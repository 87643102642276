import SharingHeader from "./components/Header/Sharing Header";
import HomeWrapper from "./components/Header/Pages/Home/HomeWrapper";
import AllSession from "./components/Header/Pages/AllSession/AllSession";
import Admin from "./components/Header/Pages/Admin/Admin";
import Projects from "./components/Header/Pages/Projects/Projects";
import Sharing from "./components/Header/Pages/Home/Sharing";
import Sessionmain from "./components/Header/Pages/Sessions/Sessions";
import Profile from "./components/Header/Pages/Profile/Profile";
import { useContext } from "react";
import AuthContext from "./hooks/useAuth.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login/Login";
import SessionComparison from "./components/Header/Pages/SessionCompare/SessionCompare";
import Layout from "./components/Header/Pages/Layout";
import Settings from "./components/Header/Pages/Settings/Settings";
import Adminwrapper from "./components/Header/Pages/Admin/Adminwrapper";

function App() {
  const auth = useContext(AuthContext);
  const isLoggedIn = auth.isLoggedIn;
  const role = auth.role;
  return (
    <Router>
      <Routes>
        {!isLoggedIn && (
          <>
            <Route path="/login" element={[<Login />]} />
            <Route path="/" element={<Navigate replace to="/login" />} />
          </>
        )}
        {isLoggedIn && (
          <Route path="/" element={[<Layout />]}>
            <Route exact path="/Dashboard" element={<HomeWrapper />} />
            <Route path="/" element={<Navigate replace to="/Dashboard" />} />
            <Route exact path="/Admin" element={<Adminwrapper />} />
            <Route exact path="/Sessions" element={<AllSession />} />
            <Route exact path="/Projects" element={<Projects />} />
            <Route
              exact
              path="/SessionComparison"
              element={<SessionComparison />}
            />
            {role === "admin" && (
              <Route path="/Admin/*" element={[<Admin />]} />
            )}
            <Route path="/sessions/*" element={[<Sessionmain />]} />
            <Route exact path="/Settings" element={<Settings />} />
            <Route exact path="/Profile" element={<Profile />} />
          </Route>
        )}
        <Route path="/" element={[<SharingHeader />]}>
          <Route exact path="/Sharing/*" element={<Sharing />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
