export const base = {
  fonts: {
    heading: "Inter, system-ui, sans-serif",
    body: "Inter, system-ui, sans-serif",
  },
  fontSizes: ["12px", "14px", "16px", "20px", "24px"],
};

export const light = {
  primary: "#9698AB",
  background: "#F5F6FA",
  nav: "#f8f8f8",
  border: "#deebf1",
  text: "#202224",
};

export const dark = {
  primary: "#FFFFFF",
  background: "#1F2327",
  nav: "#27282b",
  border: "#303236",
  text: "#f8f8f8",
};
