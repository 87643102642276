import React, { useContext } from "react";
import { ThemePreferenceContext, themesMap } from "../../../../index";
import "./Settings.css";

function Settings() {
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);

  return (
    <div style={{ marginTop: "4rem" }}>
      {/* <select
        value={currentTheme}
        onChange={(e) => {
          setCurrentTheme(e.target.value);
        }}
      >
        <option value="light">Light</option>
        <option value="dark">Dark</option>
      </select> */}
      <div
        style={{
          fontSize: 18,
          fontWeight: "600",
          color: currentTheme === "dark" ? "#FFFFFF" : "",
        }}
      >
        Level up your desktop experience with just one click - Get the App Now!{" "}
        <span style={{ color: currentTheme === "dark" ? "yellow" : "red" }}>
          (upcoming*)
        </span>
      </div>
      <div
        style={{
          marginTop: "5%",
          fontSize: 15,
          fontWeight: "600",
          color: currentTheme === "dark" ? "#FFFFFF" : "",
        }}
      >
        FOR WINDOWS
        <span style={{ marginLeft: "2%" }}>
          <button
            className="beautiful-button"
            onClick={() =>
              (window.location.href =
                "https://gamemon-desktop-builds.s3.ap-south-1.amazonaws.com/GameMon+Setup+0.1.11.exe")
            }
          >
            Click here!
          </button>
        </span>
      </div>
      <div
        style={{
          marginTop: "5%",
          fontSize: 15,
          fontWeight: "600",
          color: currentTheme === "dark" ? "#FFFFFF" : "",
        }}
      >
        FOR LINUX
        <span style={{ marginLeft: "2%" }}>
          <button
            className="beautiful-button"
            onClick={() =>
              (window.location.href =
                "https://gamemon-desktop-builds.s3.ap-south-1.amazonaws.com/GameMon-0.1.11.AppImage")
            }
          >
            Click here!
          </button>
        </span>
      </div>
      <div
        style={{
          marginTop: "5%",
          fontSize: 15,
          fontWeight: "600",
          color: currentTheme === "dark" ? "#FFFFFF" : "",
        }}
      >
        FOR MAC
        <span
          style={{
            fontSize: 18,
            color: currentTheme === "dark" ? "yellow" : "red",
          }}
        >
          *
        </span>
      </div>
      <div
        style={{
          marginTop: "5%",
          fontSize: 15,
          fontWeight: "600",
          color: currentTheme === "dark" ? "#FFFFFF" : "",
        }}
      >
       Installation Guide 
       <span style={{ marginLeft: "2%" }}>
       <button
            className="beautiful-button"
            onClick={() =>
              (window.location.href =
                "https://gamemon-desktop-builds.s3.ap-south-1.amazonaws.com/GameMon+(1)+2.docx")
            }
          >
            Click here!
          </button>
          </span>
        {/* <span style={{ marginLeft: "2%" }}>
            {" "}
            <button
              className="beautiful-button"
            >
              Check out!
            </button>
          </span> */}
      </div>
    </div>
  );
}

export default Settings;
