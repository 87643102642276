import React, { useContext } from "react";
import UserTabLayout from "../../../TabLayout/UserTabLayout";
import App from "./UsersAdmin";
import App1 from "./UserAdminInactive";
import { ThemePreferenceContext} from "../../../../index";

function Adminwrapper() {
  const { currentTheme } = useContext(ThemePreferenceContext);
  const menuItems = [
    {
      name: "active users",
      label: "Active Users",
      content: <App />,
    },
    {
      name: "inactive users",
      label: "Inactive Users",
      content: <App1 />,
    },
  ];

  return (
    <div data-theme={currentTheme} style={{ marginTop: "2rem" }}>
      <UserTabLayout
        menuItems={menuItems}
        defaultTab="active users"
      ></UserTabLayout>
    </div>
  );
}

export default Adminwrapper;
