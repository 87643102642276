import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "../../../../axios/index";
import MetricUsage from "../../Pages/Sessions/MetricUsage";
import AuthContext from "../../../../hooks/useAuth";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AndroidIcon from "@mui/icons-material/Android";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "@mui/material";
import "./AllSession.css";
import { ThemePreferenceContext } from "../../../../index";
import MetricCpuMain from "../Sessions/MetricCpuMain";
import MetricGpuMain from "../Sessions/MetricGpuMain";
import MetricFpsMain from "../Sessions/MetricFpsMain";
import MetricMemory from "../Sessions/MetricMemory";
import MetricUploadData from "../Sessions/MetricUploadData";
import MetricDownloadData from "../Sessions/MetricDownloadData";
import MetricFpsStability from "../Sessions/MetricFpsStability";
import MetricAppPower from "../Sessions/MetricAppPower";
import MetricPeakMemory from "../Sessions/MetricPeakMemory";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/AddCircle";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { Divider } from "@mui/material";
import { FormControl, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import DeleteIcon from "@mui/icons-material/Delete";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import BookIcon from "@mui/icons-material/Book";
import { debounce } from "lodash";
import AppleIcon from "@mui/icons-material/Apple";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CloseIcon from "@mui/icons-material/Close";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function Home(props) {
  const auth = useContext(AuthContext);
  const currentUserId = JSON.parse(
    sessionStorage.getItem("currentSessionData")
  );
  const uid = auth.id || currentUserId;
  const role = auth.role;
  const [User, setUser] = useState("");
  const [FirstUserdata, SetFirstUserdata] = React.useState([]);
  const [selectedUseritem, setSelectedUseritem] = React.useState("yellow");
  const [Secondsessiondata, SetSecondsessiondata] = React.useState([]);
  const [selecteditem, setSelecteditem] = React.useState("yellow");
  const [UserActive, setUserActive] = useState(false);
  const [AllSessionsActive, setAllSessionsActive] = useState(false);
  const [Allsessions, setAllsessions] = useState("");
  const [selectedDevice, setSelectedDevice] = React.useState("");
  const [allSession, setAllSession] = useState([]);
  const UsermenuRef = useRef("");
  const [calls, setcall] = useState(uid);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [sessionNoteTitle, setsessionNoteTitle] = useState(null);
  const [sessionNoteDes, setsessionNoteDes] = useState(null);
  const [sessionNotekey, setsessionNotekey] = useState(null);
  const [sessionNote, setSessionNote] = useState(false);
  const [editSessionNote, setEditSessionNote] = useState(false);
  const [sessionTitle, setSessionTitle] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [editTitle, setEditTitle] = useState(null);
  const [editSession, setEditSession] = useState(null);
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [Firstsessiondata, SetFirstsessiondata] = React.useState([]);
  const [Firstdevicedata, SetFirstdevicedata] = React.useState([]);
  const [Seconddevicedata, SetSeconddevicedata] = React.useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [device, setDevices] = useState("");
  const [date, setDate] = useState("Date");
  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [filterTab, setFilterTab] = useState(false);
  const [pageScroll, setPageScroll] = useState(1);
  const [appNameList, setAppNameList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [appName, setAppName] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const [deleteIcon, setDeleteIcon] = useState(true);
  const [sessionNoteDrop, setSessionNoteDrop] = useState(false);
  const [selectedSessionTitle, setSelectedSessionTitle] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
    setRotationAngle(0);
  };

  const handleZoomIconClick = () => {
    openModal(timeStamp);
  };

  const handleImageClick = () => {
    if (timeStamp) {
      handleZoomIconClick();
    } else {
      toast.error("Kindly,Pick a Image to Explore!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const rotateImage = () => {
    setRotationAngle(rotationAngle - 90);
  };

  const handleShareClick = () => {
    setShareLinkOpen(true);
  };

  const handleSessionDialogClose = () => {
    setShareLinkOpen(false);
  };

  const handleSessionNotes = () => {
    setSessionNote(true);
  };

  const handleSessionNotesClose = () => {
    setSessionNote(false);
  };

  useEffect(() => {
    ringko();
  }, [sessionNotekey]);

  const handleEditSessionNotes = (id) => {
    console.log("key", id);
    setsessionNotekey(id);
    setEditSessionNote(true);
  };

  function ringko() {
    addNote();
  }

  const handleEditSessionNotesClose = () => {
    setEditSessionNote(false);
  };

  const handleInputSessionTitle = (event) => {
    setsessionNoteTitle(event.target.value);
  };

  const handleInputSessionDescription = (event) => {
    setsessionNoteDes(event.target.value);
  };
  const handleInputEditSessionTitle = (event) => {
    setEditTitle(event.target.value);
    handleInputSessionTitle(editTitle);
  };

  const handleInputEditSessionDescription = (event) => {
    setEditSession(event.target.value);
    handleInputSessionDescription(editSession);
  };

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleInputChange = (event) => {
    setNewTitle(event.target.value);
  };

  const handleChangeApp = (event) => {
    const {
      target: { value },
    } = event;

    setAppName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeSession = (event) => {
    const {
      target: { value },
    } = event;

    setSessionName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeDevice = (event) => {
    const {
      target: { value },
    } = event;

    setDeviceName(typeof value === "string" ? value.split(",") : value);
  };

  const handleFilterTab = () => {
    setFilterTab(!filterTab);
  };

  const handleCloseFilter = () => {
    setFilterTab(false);
  };

  const handleApplyFilter = () => {
    const uniqueSessionNames = new Set();
    const filteredItems = [];
    if (
      appName.length === 0 &&
      sessionName.length === 0 &&
      deviceName.length === 0
    ) {
      toast.error("Choose at least 1 to Apply Filter!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    allSession.forEach((item) => {
      const matchesAppname =
        appName.length === 0 || appName.includes(item.app_name);
      const matchesSessionName =
        sessionName.length === 0 || sessionName.includes(item.sessionname);
      const matchesDeviceName =
        deviceName.length === 0 || deviceName.includes(item.device_name);

      if (matchesAppname && matchesSessionName && matchesDeviceName) {
        if (!uniqueSessionNames.has(item.sessionname)) {
          uniqueSessionNames.add(item.sessionname);
          filteredItems.push(item);
        }
      }
    });

    setFilteredList(filteredItems);
    setFilterTab(false);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
  };

  const [notes, setNotes] = useState("");

  useEffect(() => {
    let isMount = true;
    axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (isMount) {
          SetFirstUserdata(res.data.data);
        }
      })
      .catch((err) => {});
    return () => {
      isMount = false;
    };
  }, []);

  const dropdownRef = useRef(null);

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSessionNoteDrop(false);
      setUserActive(false)
    }
  };
  
  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  function Users(e, data, i) {
    const day = data.id;
    setcall(data.id);
    setAllSession([]);
    setPageScroll(1);
  }

  useEffect(() => {
    Sessions(pageScroll);
  }, [calls]);

  function singleUserItem(e, data, id) {
    setSelectedUseritem(id);
    axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        singleSessionItem(e, data, id);
      });
  }

  function Sessions(pageNum) {
    axios
      .get("/mySessions", {
        params: {
          userId: calls,
          page: pageNum,
          size: 50,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.data?.length) {
          setAllSession((prevRes) => [...prevRes, ...res.data.data]);
        }
        singleSessionItem();
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log(error);
        }
      });
  }

  function getAllFilterList() {
    const distinctValuesApp = new Set();
    const distinctValuesSession = new Set();
    const distinctValuesDevice = new Set();
    let appList = [];
    let sessionList = [];
    let deviceList = [];

    for (const obj of allSession) {
      const valueApp = obj["app_name"];
      const valueSession = obj["sessionname"];
      const valueDevice = obj["device_name"];

      if (!distinctValuesApp.has(valueApp)) {
        distinctValuesApp.add(valueApp);
        appList.push(valueApp);
      }
      if (!distinctValuesSession.has(valueSession)) {
        distinctValuesSession.add(valueSession);
        sessionList.push(valueSession);
      }
      if (!distinctValuesDevice.has(valueDevice)) {
        distinctValuesDevice.add(valueDevice);
        deviceList.push(valueDevice);
      }
    }

    setAppNameList(appList);
    setSessionList(sessionList);
    setDeviceList(deviceList);
  }

  useEffect(() => {
    if (allSession?.length) {
      getAllFilterList();
    }
  }, [allSession]);

  //Log file Download function
  async function opener() {
    const response = await fetch(
      `https://gamemon-service.indiumsoft.com/downloadlogs?fileName=${Secondsessiondata.session_id}`
    );
    if (response.status === 200) {
      window.open(
        `https://gamemon-service.indiumsoft.com/downloadlogs?fileName=${Secondsessiondata.session_id}`
      );
    } else {
      toast.error("No Log File for this Session !", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      var timer = setInterval(function () {
        clearInterval(timer);
      }, 1000);
    }
  }

  function addNote() {
    const newEditTitle = {};
    const newEditSession = {};
    notes &&
      notes?.forEach((note) => {
        if (sessionNotekey === note.id) {
          newEditTitle[note.id] = note.session_notes_title;
          newEditSession[note.id] = note.session_notes;
        }
      });

    setEditTitle(newEditTitle[sessionNotekey]);
    setsessionNoteTitle(newEditTitle[sessionNotekey]);
    setEditSession(newEditSession[sessionNotekey]);
    setsessionNoteDes(newEditSession[sessionNotekey]);
  }

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  //Edit session Note
  function editnote() {
    if (isEditing) {
      return;
    }

    setIsEditing(true);
    const values = JSON.stringify({
      id: sessionNotekey,
      sessionNotesTitle: editTitle,
      sessionNotes: editSession,
    });
    axios
      .put("/editSessionNotes", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleEditSessionNotesClose();
        sessiondressdetails(Secondsessiondata.session_id);
        {
          const errorMessage = "Session details edited !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .finally(() => {
        setIsEditing(false);
      });
  }

  //Delete Session Note
  function checksnote(id) {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true);

    axios
      .delete("/deleteSessionNotes", {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        sessiondressdetails(Secondsessiondata.session_id);
        {
          const errorMessage = "Session Deleted !";
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }

  //Display session notes
  function sessiondressdetails(sessionid) {
    axios
      .get("/fetchallSessionNotes", {
        params: {
          sessionID: sessionid,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        setNotes(res.data.data);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data)
        );
        // global.sessionNotesTitle = data.sessionNotesTitle;
      });
  }

  function singleSessionItem(e, data, id) {
    setSelecteditem(id);
    axios
      .get("/sessionDetails", {
        params: {
          DeviceId: data.device_id,
          appName: data.app_name,
          userId: uid,
          sessionId: data.session_id,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetSecondsessiondata(res.data.data[0]);
        sessiondressdetails(data.session_id);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data[0])
        );
        global.screenshot_timestamp = data.screenshot_timestamp;
        global.sessionname = data.sessionname;
        global.deviceid = data.device_id;
        global.userid = data.user_id;
        global.appname = data.app_name;
        global.sessionid = data.session_id;
        global.gpu_usage_time = data.gpu_usage_time;

        setTimeout(() => {
          setSelectedDevice(Secondsessiondata?.sessionname);
        }, 200);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          SetSecondsessiondata([]);
        }
      });
  }

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getdataid = (xaxis, yaxis) => {
    screenshot(xaxis);
  };
  // /?filename=9ee8ae91-6232-4b34-a339-5637bd6ea8d1-1701148923247.png

  const [timeStamp, setTimeStamp] = useState("");

  function screenshot(xaxis) {
    const newArray = [];
    if (Secondsessiondata?.screenshot_timestamp) {
      newArray.push(Secondsessiondata?.screenshot_timestamp);
    }

    if (newArray.length !== 0) {
      const value = Secondsessiondata?.gpu_usage_time.indexOf(xaxis);
      const values = Secondsessiondata?.screenshot_timestamp[value];
      const img = `https://gamemon-service.indiumsoft.com/getImage/?filename=${Secondsessiondata?.session_id}-${Secondsessiondata?.screenshot_timestamp[value]}.png`;
      console.log("images", img);
      setTimeStamp(img);
    }
  }

  //Add session note
  function handleFormAddSubmit() {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const values = JSON.stringify({
      sessionID: Secondsessiondata.session_id,
      sessionNotesTitle: sessionNoteTitle,
      sessionNotes: sessionNoteDes,
      user_id: uid,
      user_name: Secondsessiondata.user_name,
    });
    axios
      .post("/addSessionNotes", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        sessiondressdetails(Secondsessiondata.session_id);
        handleSessionNotesClose();

        {
          const errorMessage = "Session Note Added !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }
  
  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    SetFirstdevicedata(savedValues?.Firstdevicedata);
    setsessionNoteTitle(savedValues?.sessionNoteTitle);
    SetSeconddevicedata(savedValues?.Seconddevicedata);
    SetFirstsessiondata(savedValues?.Firstsessiondata);
    SetSecondsessiondata(savedValues?.Secondsessiondata);
    setNotes(savedValues?.notes);
    // setStartDate(savedValues?.startDate)
    setEndDate(savedValues?.endDate);
    setDevices(savedValues?.device);
    // setApplication(savedValues?.application)
    // setSessions(savedValues?.sessions)
    // setUserName(savedValues?.user_name)
    setUser(savedValues?.User);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      Firstdevicedata,
      sessionNoteTitle,
      Seconddevicedata,
      Firstsessiondata,
      Secondsessiondata,
      date,
      device,
      notes,
      // application,
      // sessions,
      startDate,
      endDate,
      User,
      // userName
    };
    window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  useEffect(() => {
    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  }, [allSession]);

  const handleClickGraph = (graphId) => {
    setSelectedGraph(graphId);
  };

  const renderGraphComponent = () => {
    switch (selectedGraph) {
      case 1:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricCpuMain try={"small"} senddata1={getdataid} />
          </div>
        );
      case 2:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricGpuMain try={"small"} senddata1={getdataid} />
          </div>
        );
      case 3:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricMemory try={"small"} senddata1={getdataid} />
          </div>
        );
      case 4:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricFpsMain try={"small"} senddata1={getdataid} />
          </div>
        );
      case 5:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricDownloadData try={"small"} senddata1={getdataid} />
          </div>
        );
      case 6:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricUploadData try={"small"} senddata1={getdataid} />
          </div>
        );
      case 7:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricPeakMemory try={"small"} senddata1={getdataid} />
          </div>
        );
      case 8:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricFpsStability try={"small"} senddata1={getdataid} />
          </div>
        );
      case 9:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricAppPower try={"small"} senddata1={getdataid} />
          </div>
        );
      default:
        return null;
    }
  };

  const handleBatteryClick = () => {
    const errorMessage = "Oops! No stats available!";
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handleChangeSearch = (event) => {
    let val = event.target.value;
    setShowClearIcon(val === "" ? "none" : "flex");
    setSearchText(val);

    const filteredItems = allSession.filter((list) =>
      list.app_name.toLowerCase().includes(val.toLowerCase())
    );

    setFilteredList(filteredItems);
  };

  const handleClickRemoveText = () => {
    setSearchText("");
    setShowClearIcon("none");

    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  };

  const handleAddBookmark = (e, sessionId) => {
    e.stopPropagation();
    const values = JSON.stringify({
      session_id: sessionId,
      user_id: uid,
    });
    axios
      .post("/bookmark", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status) {
          const errorMessage = "Bookmark Added !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteBookmark = (e, sessionId) => {
    e.stopPropagation();
    axios
      .delete("/removeBookmark", {
        data: {
          session_id: sessionId,
          user_id: uid,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status) {
          const errorMessage = "Bookmark Deleted !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteSession = (sessionId) => {
    setAllSession([]);
    axios
      .delete("/deleteSession", {
        params: {
          sessionId: sessionId,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status) {
          const Message = "Session Deleted !";
          toast.error(Message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          Sessions(pageScroll);
          setDeleteIcon(!deleteIcon);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast.error("Deletion Failed !!!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
  };

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom) {
      setPageScroll((prevRes) => prevRes + 1);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 400);

  const toggleSessionNoteDrop = (title) => {
    if (selectedSessionTitle === title) {
      setSessionNoteDrop(!sessionNoteDrop);
    } else {
      setSelectedSessionTitle(title);
      setSessionNoteDrop(true);
    }
  };

  useEffect(() => {
    if (pageScroll > 1) {
      Sessions(pageScroll);
    }
  }, [pageScroll]);

  return (
    <div style={{ width: "70%", marginTop: "5%" }}>
      {/* Left */}
      <div
        style={{
          display: "flex",
          float: "left",
          position: "fixed",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl>
            <TextField
              size="small"
              variant="outlined"
              value={searchText}
              placeholder={"Search"}
              onChange={handleChangeSearch}
              InputProps={{
                style: {
                  background: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                  border:
                    currentTheme === "dark"
                      ? "1px solid #646667"
                      : "1px solid #DFDFDF",
                  color: currentTheme === "dark" ? "#FFFFFF" : "#292E33",
                  width: "21vw",
                  borderRadius: "5px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ display: showClearIcon }}
                    onClick={handleClickRemoveText}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              background: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
              boxShadow: "0px 2px 6px #29489803",
              borderRadius: "5px",
            }}
            onClick={handleFilterTab}
          >
            <TuneIcon
              sx={{
                fontSize: 30,
                color: currentTheme === "dark" ? "#FFFFFF" : "",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div
          value={currentTheme}
          onChange={(e) => {
            setCurrentTheme(e.target.value);
          }}
          style={{
            display: AllSessionsActive != 404 ? "block" : "none",
            marginTop: "1.5%",
            cursor: "pointer",
            width: "25vw",
            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
            borderRadius: "3px",
            height: "78vh",
            overflowY: "auto",
            overflowX: "hidden",
            scrollBehavior: "smooth",
            boxShadow: "0px 2px 6px #0000000A",
          }}
          className="ScrollBar"
          onScroll={(e) => debouncedHandleScroll(e)}
        >
          {!filterTab ? (
            filteredList?.length > 0 ? (
              filteredList.map((data, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    setAllsessions(e.target.textContent);
                    setAllSessionsActive(!AllSessionsActive);
                    singleSessionItem(e, data, i);
                    setSelectedGraph("");
                  }}
                  style={{
                    background:
                      selecteditem === i
                        ? "transparent linear-gradient(180deg, #6398F5 0%, #4475EE 100%) 0% 0% no-repeat padding-box"
                        : currentTheme === "dark"
                        ? "#292E33"
                        : "white",
                    color:
                      selecteditem === i
                        ? "white"
                        : currentTheme === "dark"
                        ? "white"
                        : "black",
                    fontSize: "20px",
                    borderRadius: "8px",
                    marginLeft: "5px",
                    marginBottom: "-4px",
                    width: "97%",
                  }}
                >
                  {/* Session Information */}
                  <ListItem>
                    {data.device_name === "apple" ? (
                      <AppleIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    ) : (
                      <AndroidIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    )}
                    <ListItemText>
                      <div
                        // variant="body"
                        style={{
                          fontWeight: "bold",
                          marginLeft: "15px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          // textTransform:"capitalize",
                          color:
                            selecteditem === i
                              ? "white"
                              : currentTheme === "dark"
                              ? "white"
                              : "#4779EF",
                        }}
                        title={data?.app_name}
                        className="sess-appname"
                      >
                        {/* {data?.app_name} */}
                        {data?.app_name
                          ? data?.app_name.length > 25
                            ? data?.app_name.substring(0, 25) + "..."
                            : data?.app_name
                          : ""}
                        {/* {data?.app_name && (data.app_name.includes('.') ? data.app_name.split('.').pop().slice(-20) : data.app_name)} */}
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "capitalize",
                          marginLeft: "15px",
                          color:
                            selecteditem === i
                              ? "white"
                              : currentTheme === "dark"
                              ? "#C5CACF"
                              : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        {data.device_name}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginLeft: "15px",
                          color:
                            selecteditem === i
                              ? "white"
                              : currentTheme === "dark"
                              ? "#C5CACF"
                              : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        Time Played:{" "}
                        <span
                          style={{
                            color:
                              selecteditem === i
                                ? "white"
                                : currentTheme === "dark"
                                ? "#C5CACF"
                                : "#494949",
                            fontWeight: "600",
                          }}
                          id="scroll-fonts"
                        >
                          {data.session_duration}
                        </span>
                      </Typography>
                    </ListItemText>
                    <div
                      style={{
                        marginBottom: "2vh",
                        display: "flex",
                        gap: "10%",
                      }}
                    >
                      {selecteditem === i && (
                        <DeleteIcon
                          className="scroll-icon"
                          onClick={() => handleDeleteSession(data.session_id)}
                        />
                      )}

                      {data.is_bookmarked === "true" ? (
                        <BookIcon
                          className="scroll-icon"
                          onClick={(e) =>
                            handleDeleteBookmark(e, data.session_id)
                          }
                        />
                      ) : (
                        <BookOutlinedIcon
                          className="scroll-icon"
                          onClick={(e) => handleAddBookmark(e, data.session_id)}
                        />
                      )}
                    </div>
                  </ListItem>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                      margin: "5px 0",
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: currentTheme === "dark" ? "white" : "black",
                }}
              >
                {AllSessionsActive == 404
                  ? "No Recent sessions"
                  : "No Recent logs!"}
              </div>
            )
          ) : (
            // Filter tab
            <div
              style={{ display: "flex", flexDirection: "column", margin: "5%" }}
            >
              <h4
                style={{
                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                                sans-serif`,
                  color: currentTheme === "dark" ? "#A2B1C2" : "#494949",
                  fontSize: "14px",
                }}
              >
                Filters:
              </h4>
              <FormControl sx={{ m: 5, width: 600 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "4%",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ paddingTop: "9%" }}>
                    <InputLabel
                      id="demo-multiple-name-label"
                      style={{
                        color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                        fontSize: "14px",
                        fontFamily: "normal normal bold 16px/21px Product Sans",
                      }}
                    >
                      Device
                    </InputLabel>
                    <Select
                      multiple
                      value={deviceName}
                      onChange={handleChangeDevice}
                      input={<OutlinedInput />}
                      // MenuProps={MenuProps}
                      style={{
                        width: "22vw",
                        height: "6vh",
                        color: currentTheme === "dark" ? "#818E94" : "black",
                      }}
                    >
                      {deviceList.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div style={{ paddingTop: "9%" }}>
                    <InputLabel
                      id="demo-multiple-name-label"
                      style={{
                        color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                        fontSize: "14px",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                  sans-serif`,
                      }}
                    >
                      App Name
                    </InputLabel>
                    <Select
                      multiple
                      value={appName}
                      onChange={handleChangeApp}
                      input={<OutlinedInput />}
                      // MenuProps={MenuProps}
                      style={{
                        width: "22vw",
                        height: "6vh",
                        color: currentTheme === "dark" ? "#818E94" : "black",
                      }}
                    >
                      {appNameList.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div style={{ paddingTop: "9%" }}>
                    <InputLabel
                      id="demo-multiple-name-label"
                      style={{
                        color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                        fontSize: "14px",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                                sans-serif`,
                      }}
                    >
                      Session
                    </InputLabel>
                    <Select
                      multiple
                      value={sessionName}
                      onChange={handleChangeSession}
                      input={<OutlinedInput />}
                      // MenuProps={MenuProps}
                      style={{
                        width: "22vw",
                        height: "6vh",
                        color: currentTheme === "dark" ? "#818E94" : "black",
                      }}
                    >
                      {sessionList.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    marginTop: "25vh",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      width: "180px",
                      height: "48px",
                      borderRadius: "40px",
                      marginTop: "-10vh",
                      background:
                        "transparent linear-gradient(180deg, #659AF5 0%, #4374EE 100%) 0% 0% no-repeat padding-box",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                      sans-serif`,
                    }}
                    // className="filterButton"
                    onClick={handleApplyFilter}
                  >
                    Apply Filters
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "48px",
                      borderRadius: "40px",
                      marginTop: "-10vh",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#494949",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                       'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                       sans-serif`,
                    }}
                    // className="filterButton"
                    onClick={handleCloseFilter}
                  >
                    Cancel
                  </Button>
                </div>
              </FormControl>
            </div>
          )}
        </div>
      </div>
      {/* right */}
      <div className="right">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div
              style={{
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
              className="sessionContainer"
            >
              <div
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                }}
                className="icon-container"
              >
                <PhoneAndroidIcon className="icon" />
              </div>
              <div style={{ width: "80%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "white" : "#293134",
                        // textTransform:"capitalize",
                      }}
                      title={Secondsessiondata?.app_name}
                      className="container-fonts"
                    >
                      {/* {Secondsessiondata?.app_name} */}
                      {Secondsessiondata?.app_name
                        ? Secondsessiondata?.app_name.length > 25
                          ? Secondsessiondata?.app_name.substring(0, 25) + "..."
                          : Secondsessiondata?.app_name
                        : ""}
                      {/* {Secondsessiondata?.app_name && (Secondsessiondata.app_name.includes('.') ? Secondsessiondata.app_name.split('.').pop().slice(-20) : Secondsessiondata.app_name)} */}
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts1"
                    >
                      <span>Version: {""}</span>
                      <span style={{ fontWeight: "600" }}>
                        {Secondsessiondata?.version_name}
                      </span>
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts2"
                    >
                      <span>Last Updated: {""}</span>
                      <span style={{ fontWeight: "600" }}>
                        {Secondsessiondata?.created_at}
                      </span>
                    </div>
                  </div>
                  <div>
                    {Secondsessiondata === undefined ? (
                      <MoreVertIcon
                        disabled={true}
                        sx={{ fontSize: 20, color: "#4779EF" }}
                      />
                    ) : (
                      <>
                        <MoreVertIcon
                          onClick={handleClick}
                          size="small"
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          sx={{
                            fontSize: 20,
                            color: "#4779EF",
                            cursor: "pointer",
                          }}
                        />
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              backgroundColor:
                                currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32)",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "& .MuiMenuItem-root": {
                                fontSize: "14px",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                                fontWeight: "400",
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                                backgroundColor:
                                  currentTheme === "dark"
                                    ? "#363C43"
                                    : "#FFFFFF",
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: "yellow",
                                },
                                "& .MuiMenuItem-root:hover": {
                                  backgroundColor: "pink",
                                },
                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                  backgroundColor: "red",
                                },
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            onClick={handleShareClick}
                            style={{
                              fontSize: "14px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                              fontWeight: "400",
                            }}
                          >
                            Share Session
                          </MenuItem>
                          <Dialog
                            open={shareLinkOpen}
                            onClose={handleSessionDialogClose}
                            PaperProps={{
                              style: {
                                backgroundColor:
                                  currentTheme === "dark"
                                    ? "#1F2327"
                                    : "#FFFFFF",
                              },
                            }}
                          >
                            <DialogTitle
                              style={{
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                              }}
                            >
                              Share Session
                            </DialogTitle>
                            <CancelIcon
                              onClick={handleClose}
                              style={{
                                cursor: "pointer",
                                marginLeft: "93%",
                                marginTop: "-7vh",
                                marginBottom: "1vh",
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                              }}
                            />
                            <Divider
                              style={{ margin: "1vh", backgroundColor: "grey" }}
                            />
                            <DialogContent>
                              <h5
                                style={{
                                  color: "#818E94",
                                  fontsize: "10px",
                                  fontWeight: "300",
                                  marginBottom: "1%",
                                }}
                              >
                                Copy the link below
                              </h5>
                              <TextField
                                fullWidth
                                InputProps={{
                                  style: { color: "#818E94" },
                                }}
                                value={`http://gamemon.s3-website-us-west-2.amazonaws.com/Sharing/gamemon-service.indiumsoft.com/shareSessionDetails?DeviceId=${Secondsessiondata?.device_id}&appName=${Secondsessiondata?.app_name}&sessionId=${Secondsessiondata?.session_id}&userId=${uid}`}
                              />
                              <CopyToClipboard
                                text={`http://gamemon.s3-website-us-west-2.amazonaws.com/Sharing/gamemon-service.indiumsoft.com/shareSessionDetails?DeviceId=${Secondsessiondata?.device_id}&appName=${Secondsessiondata?.app_name}&sessionId=${Secondsessiondata?.session_id}&userId=${uid}`}
                                onCopy={() => alert("Copied Successfully!!!")}
                              >
                                <button
                                  style={{
                                    marginTop: "25px",
                                    width: "auto",
                                    height: "40px",
                                    // boxShadow: "5px 5px 5px silver",
                                    backgroundColor: "#4779EF",
                                    padding: "7px 15px 5px 15px",
                                    borderRadius: "5px",
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                    marginLeft: "33%",
                                    border: "none",
                                    outline: "none",
                                  }}
                                >
                                  Copy Link
                                </button>
                              </CopyToClipboard>
                            </DialogContent>
                          </Dialog>
                          <MenuItem
                            onClick={() => {
                              if (Secondsessiondata?.session_id !== "") {
                                window.open(
                                  `https://gamemon-service.indiumsoft.com/getReport?sessionID=${Secondsessiondata?.session_id}`
                                );
                              }
                            }}
                            style={{
                              fontSize: "14px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                              fontWeight: "400",
                            }}
                          >
                            Download Report
                          </MenuItem>
                          <MenuItem
                            onClick={opener}
                            style={{
                              fontSize: "14px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                              fontWeight: "400",
                            }}
                          >
                            Download log File
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                    }}
                    className="version-fonts3"
                  >
                    <span>Device:{""}</span>
                    <span
                      style={{
                        fontWeight: "600",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={Secondsessiondata?.device_name}
                    >
                      {/* {Secondsessiondata?.device_name} */}
                      {Secondsessiondata?.device_name
                        ? Secondsessiondata?.device_name.length > 10
                          ? Secondsessiondata?.device_name.substring(0, 8) +
                            "..."
                          : Secondsessiondata?.device_name
                        : ""}
                    </span>
                  </div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                      marginLeft: "-0.5px",
                    }}
                    className="version-fonts3"
                  >
                    <span>OS version:{""}</span>
                    <span style={{ fontWeight: "600" }}>
                      {Secondsessiondata?.os_version}
                    </span>
                  </div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                      marginLeft: "-0.5px",
                    }}
                    className="version-fonts3"
                  >
                    <span>GPU:{""}</span>
                    <span
                      style={{
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={
                        Secondsessiondata?.gpu_vendor?.includes(",")
                          ? Secondsessiondata?.gpu_vendor
                              .split(",", 2)
                              .join(",")
                          : Secondsessiondata?.gpu_vendor
                      }
                    >
                      {Secondsessiondata?.gpu_vendor?.includes(",")
                        ? Secondsessiondata?.gpu_vendor
                            ?.split(",")[1]
                            ?.substring(0, 8) + "..."
                        : Secondsessiondata?.gpu_vendor?.substring(0, 8)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div
              style={{
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
              className="user-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fonts"
                  >
                    User
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                    }}
                    title={Secondsessiondata?.user_name}
                    className="user-fonts"
                  >
                    {Secondsessiondata?.user_name}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fonts"
                  >
                    Session Title
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                    }}
                    title={Secondsessiondata?.sessionname}
                    className="user-fonts"
                  >
                    {Secondsessiondata?.sessionname
                      ? Secondsessiondata?.sessionname.length > 20
                        ? Secondsessiondata?.sessionname.substring(0, 20) +
                          "..."
                        : Secondsessiondata?.sessionname
                      : ""}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fonts"
                  >
                    Session Duration
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                    }}
                    className="user-fonts"
                  >
                    {Secondsessiondata?.total_duration}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                minWidth: "235px",
                width: "235px",
                borderRadius: "5px",
                marginLeft: "1vw",
              }}
            >
              {/* User Details Dropdown */}
              {role === "admin" && (
                <div className="dropdown-containerss" ref={UsermenuRef}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      closeDropdown(UserActive);
                      setUserActive(!UserActive);
                    }}
                    className="dropdown-buttonss"
                    style={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "white",
                      color: currentTheme === "dark" ? "white" : "black",
                      textTransform: "capitalize",
                    }}
                    ref={dropdownRef}
                  >
                    <div>
                      {User ? User : "User"}
                      <span className="down-arrowss">&#9660;</span>
                    </div>
                  </div>

                  <div
                    className="dropdown-contentss"
                    style={{
                      display: UserActive ? "block" : "none",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "white",
                    }}
                  >
                    <div
                      className="scrollable-listss"
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {FirstUserdata &&
                        FirstUserdata.slice()
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((data, i) => (
                            <div
                              onClick={(e) => {
                                setUser(e.target.textContent);
                                auth.userId(data.id);
                                setUserActive(!UserActive);
                                singleUserItem(e, data, i);
                                Users(e, data, i);
                              }}
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                              }}
                              key={i}
                            >
                              {data.name}
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {role === "admin" ? (
              <div
                style={{
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  color: currentTheme === "dark" ? "white" : "black",
                  overflowY: "auto",
                  overflowX: "hidden",
                  scrollBehavior: "smooth",
                }}
                className="sessionNote-Container"
              >
                <h3 className="font-weight">Session Notes</h3>
                <span style={{ float: "right", marginTop: "-30px" }}>
                  <AddIcon
                    className="add-icon"
                    onClick={handleSessionNotes}
                  ></AddIcon>
                </span>
                {notes !== null && (
                  <div>
                    <div>
                      {notes &&
                        notes?.map((note, index) => (
                          <div
                            key={index}
                            className="sessDrop-container"
                            ref={dropdownRef}
                          >
                            <div
                              onClick={() =>
                                toggleSessionNoteDrop(note.session_notes_title)
                              }
                              className="sessDrop-button"
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                                textTransform: "capitalize",
                              }}
                            >
                              <div>
                                {note.session_notes_title}
                                <span className="down-arrow">&#9660;</span>
                              </div>
                            </div>
                            <div
                              style={{
                                display:
                                  selectedSessionTitle ===
                                    note.session_notes_title && sessionNoteDrop
                                    ? "block"
                                    : "none",
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                              }}
                              className="sessDrop-content"
                            >
                              <div
                                className="scrollable-list"
                                style={{
                                  backgroundColor:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "white",
                                  color:
                                    currentTheme === "dark" ? "white" : "black",
                                  textTransform: "capitalize",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "white",
                                    color:
                                      currentTheme === "dark"
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {note.session_notes}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div>
                  <Dialog
                    open={sessionNote}
                    onClose={handleSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 350 },
                      style: {
                        borderRadius: "5px",
                        backgroundColor:
                          currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      },
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <DialogTitle
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Add Notes
                      </DialogTitle>
                      <CancelIcon
                        onClick={handleSessionNotesClose}
                        style={{
                          cursor: "pointer",
                          marginLeft: "160px",
                          marginTop: "20px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      />
                    </div>
                    <Divider
                      style={{ margin: "1vh", backgroundColor: "grey" }}
                    />
                    <DialogContent style={{ height: "285px" }}>
                      <div style={{ marginTop: "-5px", color: "#818E94" }}>
                        Title{" "}
                      </div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          onChange={handleInputSessionTitle}
                          fullWidth
                          inputProps={{
                            style: {
                              height: "2px",
                              color: "#818E94",
                            },
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "17px", color: "#818E94" }}>
                        Description
                      </div>
                      <div
                        style={{
                          marginTop: "3px",
                          height: "40px",
                          width: "300px",
                        }}
                      >
                        <TextField
                          onChange={handleInputSessionDescription}
                          fullWidth
                          multiline
                          rows={4}
                          inputProps={{
                            style: {
                              height: "50px",
                              color: "#818E94",
                            },
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        onClick={handleFormAddSubmit}
                        disabled={isSubmitting}
                        style={{
                          width: "300px",
                          height: "48px",
                          borderRadius: "14px",
                          marginTop: "62px",
                          backgroundColor: "#278EF1",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                        sans-serif`,
                        }}
                      >
                        Save
                      </Button>
                    </DialogContent>
                    {/* <DialogContent
                      style={{
                        marginLeft: "5%",
                        display: "flex",
                        height: "30px",
                        marginTop: "-25px",
                        overflow: "hidden",
                      }}
                    >
                      <List>
                        <div
                          style={{
                            color: "#FFFFFF",
                            marginTop: "-21%",
                            background: "#278EF1",
                            borderRadius: "25px",
                            width: "130px",
                            height: "35px",
                            padding: "3px",
                            fontSize: "15px",
                            gap: "10px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={handleFormAddSubmit}
                            disabled={isSubmitting}
                            style={{
                              marginTop: "-5%",
                              paddingLeft: "36%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Save
                          </ListItem>
                        </div>
                      </List>

                      <List onClick={handleSessionNotesClose}>
                        <div
                          style={{
                            color: "#278EF1",
                            marginTop: "-21%",
                            border: "2px solid #278EF1",
                            boxShadow: "white",
                            borderRadius: "25px",
                            width: "130px",
                            height: "35px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            style={{
                              marginTop: "-5%",
                              paddingLeft: "33%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Cancel
                          </ListItem>
                        </div>
                      </List> 
                    </DialogContent> */}
                  </Dialog>
                </div>

                <div>
                  <Dialog
                    open={editSessionNote}
                    onClose={handleEditSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 250 },
                      style: { borderRadius: "25px" },
                    }}
                  >
                    <DialogTitle>
                      EDIT SESSION NOTES{sessionNotekey?.sessionNoteDes}
                    </DialogTitle>
                    {/* {notes && notes?.map((note) => (
          <div> 
            <div style={{marginLeft:'20px',marginTop:'-10px'}}>{sessionNotekey === note.id && note.session_notes_title}</div>
            <div style={{marginLeft:'20px'}}>{sessionNotekey === note.id && note?.session_notes}</div>     
            </div>
        ))}  */}
                    <Divider />
                    <DialogContent style={{ height: "200px" }}>
                      <div style={{ marginTop: "-5px" }}>Title</div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          value={editTitle}
                          onChange={handleInputEditSessionTitle}
                          fullWidth
                          inputProps={{
                            style: {
                              height: "2px",
                            },
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "17px" }}>Description</div>
                      <div
                        style={{
                          marginTop: "3px",
                          height: "40px",
                          width: "300px",
                        }}
                      >
                        <TextField
                          value={editSession}
                          onChange={handleInputEditSessionDescription}
                          fullWidth
                          multiline
                          rows={4}
                          inputProps={{ style: { height: "50px" } }}
                        />
                      </div>
                    </DialogContent>
                    <DialogContent
                      style={{
                        marginLeft: "5%",
                        display: "flex",
                        height: "30px",
                        marginTop: "-25px",
                      }}
                    >
                      <Divider />
                      <List>
                        <div
                          style={{
                            color: "#FFFFFF",
                            marginTop: "-8%",
                            background: "#278EF1",
                            borderRadius: "25px",
                            width: "130px",
                            height: "35px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={editnote}
                            style={{
                              paddingLeft: "36%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Update
                          </ListItem>
                        </div>
                      </List>

                      <List onClick={handleEditSessionNotesClose}>
                        <div
                          style={{
                            color: "#278EF1",
                            marginTop: "-8%",
                            border: "2px solid #278EF1",
                            boxShadow: "white",
                            borderRadius: "25px",
                            width: "120px",
                            height: "31px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            style={{
                              paddingLeft: "33%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Cancel
                          </ListItem>
                        </div>
                      </List>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            ) : (
              <div
                style={{
                  minWidth: "25%",
                  height: "225px",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "8px",
                  boxShadow: "0px 3px 6px #0000000A",
                  position: "relative",
                  right: "-19px",
                  padding: "10px",
                  color: currentTheme === "dark" ? "white" : "black",
                }}
              >
                <h3 style={{ fontWeight: "600" }}>Session Notes</h3>
                <span style={{ float: "right", marginTop: "-30px" }}>
                  <AddIcon
                    sx={{
                      fontSize: 35,
                      color: "#4779EF",
                      cursor: "pointer",
                      boxShadow: "#0000004D",
                    }}
                    onClick={handleSessionNotes}
                  ></AddIcon>
                </span>
                {notes !== null && (
                  <div>
                    <div>
                      {notes &&
                        notes?.map((note, index) => (
                          <div key={index} className="sessDrop-container">
                            <div
                              onClick={() =>
                                toggleSessionNoteDrop(note.session_notes_title)
                              }
                              className="sessDrop-button"
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                              }}
                            >
                              <div>
                                {note.session_notes_title}
                                <span className="down-arrow">&#9660;</span>
                              </div>
                            </div>
                            <div
                              style={{
                                display:
                                  selectedSessionTitle ===
                                    note.session_notes_title && sessionNoteDrop
                                    ? "block"
                                    : "none",
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                              }}
                              className="sessDrop-content"
                            >
                              <div
                                className="scrollable-list"
                                style={{
                                  backgroundColor:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "white",
                                  color:
                                    currentTheme === "dark" ? "white" : "black",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "white",
                                    color:
                                      currentTheme === "dark"
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {note.session_notes}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}{" "}
                    </div>
                  </div>
                )}

                <div>
                  <Dialog
                    open={sessionNote}
                    onClose={handleSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 350 },
                      style: {
                        borderRadius: "5px",
                        backgroundColor:
                          currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      },
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <DialogTitle
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Add Notes
                      </DialogTitle>
                      <CancelIcon
                        onClick={handleSessionNotesClose}
                        style={{
                          cursor: "pointer",
                          marginLeft: "160px",
                          marginTop: "20px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      />
                    </div>
                    <Divider
                      style={{ margin: "1vh", backgroundColor: "grey" }}
                    />
                    <DialogContent style={{ height: "285px" }}>
                      <div style={{ marginTop: "-5px", color: "#818E94" }}>
                        Title{" "}
                      </div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          onChange={handleInputSessionTitle}
                          fullWidth
                          inputProps={{
                            style: {
                              height: "2px",
                              color: "#818E94",
                            },
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "17px", color: "#818E94" }}>
                        Description
                      </div>
                      <div
                        style={{
                          marginTop: "3px",
                          height: "40px",
                          width: "300px",
                        }}
                      >
                        <TextField
                          onChange={handleInputSessionDescription}
                          fullWidth
                          multiline
                          rows={4}
                          inputProps={{
                            style: {
                              height: "50px",
                              color: "#818E94",
                            },
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        onClick={handleFormAddSubmit}
                        disabled={isSubmitting}
                        style={{
                          width: "300px",
                          height: "48px",
                          borderRadius: "14px",
                          marginTop: "62px",
                          backgroundColor: "#278EF1",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                        sans-serif`,
                        }}
                      >
                        Save
                      </Button>
                    </DialogContent>
                    {/* <DialogContent
                      style={{
                        marginLeft: "5%",
                        display: "flex",
                        height: "30px",
                        marginTop: "-25px",
                        overflow: "hidden",
                      }}
                    >
                      <List>
                        <div
                          style={{
                            color: "#FFFFFF",
                            marginTop: "-21%",
                            background: "#278EF1",
                            borderRadius: "25px",
                            width: "130px",
                            height: "35px",
                            padding: "3px",
                            fontSize: "15px",
                            gap: "10px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={handleFormAddSubmit}
                            disabled={isSubmitting}
                            style={{
                              marginTop: "-5%",
                              paddingLeft: "36%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Save
                          </ListItem>
                        </div>
                      </List>

                      <List onClick={handleSessionNotesClose}>
                        <div
                          style={{
                            color: "#278EF1",
                            marginTop: "-21%",
                            border: "2px solid #278EF1",
                            boxShadow: "white",
                            borderRadius: "25px",
                            width: "130px",
                            height: "35px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            style={{
                              marginTop: "-5%",
                              paddingLeft: "33%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Cancel
                          </ListItem>
                        </div>
                      </List> 
                    </DialogContent> */}
                  </Dialog>
                </div>

                <div>
                  <Dialog
                    open={editSessionNote}
                    onClose={handleEditSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 250 },
                      style: { borderRadius: "25px" },
                    }}
                  >
                    <DialogTitle>
                      EDIT SESSION NOTES{sessionNotekey?.sessionNoteDes}
                    </DialogTitle>
                    {/* {notes && notes?.map((note) => (
              <div> 
                <div style={{marginLeft:'20px',marginTop:'-10px'}}>{sessionNotekey === note.id && note.session_notes_title}</div>
                <div style={{marginLeft:'20px'}}>{sessionNotekey === note.id && note?.session_notes}</div>     
                </div>
            ))}  */}
                    <Divider />
                    <DialogContent style={{ height: "200px" }}>
                      <div style={{ marginTop: "-5px" }}>Title</div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          value={editTitle}
                          onChange={handleInputEditSessionTitle}
                          fullWidth
                          inputProps={{
                            style: {
                              height: "2px",
                            },
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "17px" }}>Description</div>
                      <div
                        style={{
                          marginTop: "3px",
                          height: "40px",
                          width: "300px",
                        }}
                      >
                        <TextField
                          value={editSession}
                          onChange={handleInputEditSessionDescription}
                          fullWidth
                          multiline
                          rows={4}
                          inputProps={{ style: { height: "50px" } }}
                        />
                      </div>
                    </DialogContent>
                    <DialogContent
                      style={{
                        marginLeft: "5%",
                        display: "flex",
                        height: "30px",
                        marginTop: "-25px",
                      }}
                    >
                      <Divider />
                      <List>
                        <div
                          style={{
                            color: "#FFFFFF",
                            marginTop: "-8%",
                            background: "#278EF1",
                            borderRadius: "25px",
                            width: "130px",
                            height: "35px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={editnote}
                            style={{
                              paddingLeft: "36%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Update
                          </ListItem>
                        </div>
                      </List>

                      <List onClick={handleEditSessionNotesClose}>
                        <div
                          style={{
                            color: "#278EF1",
                            marginTop: "-8%",
                            border: "2px solid #278EF1",
                            boxShadow: "white",
                            borderRadius: "25px",
                            width: "120px",
                            height: "31px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            style={{
                              paddingLeft: "33%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Cancel
                          </ListItem>
                        </div>
                      </List>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Metric Grid */}
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginLeft: "20vw",
            }}
            className="metric-grid"
          >
            {/* First Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.cpu_average_usage !== undefined
                      ? Secondsessiondata?.cpu_average_usage
                      : 0
                  }
                  text="CPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 1}
                  onClick={() => handleClickGraph(1)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.gpu_average_usage !== undefined
                      ? Secondsessiondata?.gpu_average_usage
                      : 0
                  }
                  text="GPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 2}
                  onClick={() => handleClickGraph(2)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.memory_average_usage !== undefined
                      ? Secondsessiondata?.memory_average_usage
                      : 0
                  }
                  text="Average Memory Usage"
                  unit="MB"
                  max={2048}
                  selected={selectedGraph === 3}
                  onClick={() => handleClickGraph(3)}
                />
              </div>
            </div>
            {/* Second Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.average_fps_value !== undefined
                      ? Secondsessiondata?.average_fps_value
                      : 0
                  }
                  text="FPS"
                  unit=""
                  max={100}
                  selected={selectedGraph === 4}
                  onClick={() => handleClickGraph(4)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.download_data_usage_average !== undefined
                      ? Secondsessiondata?.download_data_usage_average
                      : 0
                  }
                  text="Total Data Downloaded"
                  unit="KB"
                  max={6000}
                  selected={selectedGraph === 5}
                  onClick={() => handleClickGraph(5)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.upload_data_usage_average !== undefined
                      ? Secondsessiondata?.upload_data_usage_average
                      : 0
                  }
                  text="Total Data Uploaded"
                  unit="KB"
                  max={6000}
                  selected={selectedGraph === 6}
                  onClick={() => handleClickGraph(6)}
                />
              </div>
            </div>
            {/* Third Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.peak_memory_value !== undefined
                      ? Secondsessiondata?.peak_memory_value
                      : 0
                  }
                  text="Average Peak Memory"
                  unit="MB"
                  max={2048}
                  selected={selectedGraph === 7}
                  onClick={() => handleClickGraph(7)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.fps_stability !== undefined
                      ? Secondsessiondata?.fps_stability
                      : 0
                  }
                  text="FPS Stability"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 8}
                  onClick={handleBatteryClick}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value="0"
                  text="Battery Usage"
                  unit="%"
                  max={100}
                  onClick={handleBatteryClick}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: "21vw",
              display: "grid",
              // gridTemplateColumns: "repeat(3, 1fr)",
              // gridTemplateRows: "repeat(2, 1fr)",
              gap: "1%",
              width: "100%",
              height: "100%",
              marginTop: "1rem",
            }}
          >
            <div style={{ gridRow: "1 / span 1" }}>
              <div
                style={{
                  width: "18vw",
                  height: "300px",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                }}
              >
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    fontSize: "14px",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Screen Flow
                </p>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    style={{
                      display: "block",
                      marginTop: "3%",
                      marginBottom: "3%",
                      borderRadius: "5px",
                      width: "50%",
                      color: currentTheme === "dark" ? "white" : "black",
                      fontSize: "12px",
                    }}
                    src={timeStamp}
                    alt="Oops! No Image!"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <TravelExploreIcon
                      style={{
                        fontSize: "2rem",
                        color: currentTheme === "dark" ? "white" : "black",
                        cursor: "pointer",
                      }}
                      onClick={handleImageClick}
                    />
                  </div>
                </div>
              </div>

              {/* Modal */}
              {isModalOpen && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 999,
                    backdropFilter: "blur(5px)",
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                          cursor: "zoom-in",
                        }}
                      >
                        <img
                          style={{
                            display: "block",
                            margin: "0.5%",
                            width: "20%",
                            color: currentTheme === "dark" ? "white" : "black",
                            fontSize: "12px",
                            borderRadius: "5px",
                            transform: `rotate(${rotationAngle}deg)`,
                            transition: "0.5s ease-in-out",
                          }}
                          src={selectedImage}
                          alt="Oops! No Image!"
                        />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 180,
                            padding: "10px",
                          }}
                        >
                          <CloseIcon
                            CloseIcon
                            style={{
                              fontSize: "2rem",
                              color:
                                currentTheme === "dark" ? "white" : "black",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={closeModal}
                          ></CloseIcon>
                        </div>
                        <br />
                        <ScreenRotationIcon
                          style={{
                            fontSize: "2rem",
                            color: currentTheme === "dark" ? "white" : "black",
                            cursor: "pointer",
                          }}
                          onClick={rotateImage}
                        />
                      </div>
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              )}
            </div>
            <div style={{ gridRow: "1 / span 1" }}>
              <div
                style={{
                  width: "34vw",
                  height: "300px",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    fontSize: "14px",
                    padding: "5px",
                    fontWeight: "bold",
                    // textTransform: "capitalize",
                  }}
                >
                  {/* {Secondsessiondata?.app_name} */}
                  {Secondsessiondata?.app_name
                    ? Secondsessiondata?.app_name.length > 25
                      ? Secondsessiondata?.app_name.substring(0, 25) + "..."
                      : Secondsessiondata?.app_name
                    : ""}
                  {/* {Secondsessiondata?.app_name && (Secondsessiondata.app_name.includes('.') ? Secondsessiondata.app_name.split('.').pop().slice(-20) : Secondsessiondata.app_name)} */}
                </p>
                {/* <hr /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "auto",
                    cursor: "pointer",
                    marginTop: "3vh",
                  }}
                  className="ScrollBars"
                >
                  {renderGraphComponent()}
                </div>
              </div>
            </div>
          </div>
          {/* Render Graph Component */}
          {/* <div currentTheme={currentTheme} style={{ marginLeft: "20vw" }}>
            {renderGraphComponent()}
          </div> */}
        </div>
      </div>
      <ToastContainer
        toastStyle={{
          marginTop: "4rem",
          borderRadius: "10px",
          backgroundColor: "white",
          alignContent: "center",
          height: 50,
          margin: 10,
          width: 300,
          closeOnClick: true,
          textAlign: "center",
        }}
        closeButton={false}
        hideProgressBar={false}
        autoClose={2000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
      />
    </div>
  );
}
