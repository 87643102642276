import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import TextField from "@mui/material/TextField";
import { TextField, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Divider } from "@mui/material";
import "./Admin.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext} from "../../../../index";
import "./Admin.css";

export default function FormAddDialog({
  open,
  handleClose,
  data,
  onChange,
  onEmail,
  handleFormSubmit,
  onRoleChange,
  onDateChange,
  formData,
  updateId,
}) {
  const { name, email, password, phone_number, role, access_end_date } = data;

  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);

  const [date, setDate] = useState(new Date());

  // const inputStyles = {
  //   width: '200px',
  //   height: '40px',
  // };

  // const calendarStyles = {
  //   width: '300px', }

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
            // maxWidth: "xl",
            width: "32vw",
            height: "450px",
            backgroundColor: currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <DialogTitle
            style={{
              fontSize: "50px",
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
            // id="alert-dialog-title"
          >
            Create User
          </DialogTitle>
          <CancelIcon
            onClick={handleClose}
            style={{
              marginTop: "4.3%",
              cursor: "pointer",
              marginRight: "2.5vh",
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
          />
        </div>
        <Divider
          style={{
            margin: "0vw 1.5vw",
            width: "29vw",
            backgroundColor: "grey",
          }}
        />
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <form>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ color: "#818E94" }}>Name</Typography>
                  <TextField
                    id="name"
                    value={name}
                    onChange={(e) => onChange(e)}
                   InputProps={{
                      placeholder: "Enter Name",
                      inputProps: {
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",textTransform:"capitalize"
                        },
                      },
                    }}
                    // label="Name"
                    variant="outlined"
                    style={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                    }}
                    margin="dense"
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ color: "#818E94" }}>Password</Typography>
                  <TextField
                    id="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    InputProps={{
                      placeholder: "Enter Password",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        },
                    }}
                    // label="Password"
                    variant="outlined"
                    style={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                    }}
                    margin="dense"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ color: "#818E94" }}>
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    type="email"
                    value={formData?.email}
                    onChange={onEmail}
                    InputProps={{
                      placeholder: "Enter Email",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        },
                    }}
                    // label="Email"
                    variant="outlined"
                    style={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                    }}
                    margin="dense"
                  />
                </div>

                {/* <TextField
              id="phone_number"
              value={phone_number}
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  onChange(e);
                }
              }}
              InputProps={{
                placeholder:"Enter phone_number",
                style:{color:"#818E94"} 
              }}
              InputLabelProps={{
                style: { color: "#818E94"} 
              }}
              type="number"
              label="Phone"
              style={{ marginLeft: "2vh",backgroundColor:currentTheme === "dark" ? "#292E33" : "#FFFFFF" }}
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 10 }}
            /> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ color: "#818E94", marginTop: "2%" }}>
                    Access End date
                  </Typography>
                  <TextField
                    id="access_end_date"
                    type="date"
                    // label="End Date"
                    value={access_end_date}
                    onChange={onDateChange}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                      style: {
                        color: "#818E94",
                      },
                    }}
                    style={{
                      marginTop: "2%",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                    }}
                    margin="dense"
                  />
                </div>
              </div>
            </div>
            <div>
              <Typography style={{ color: "#818E94" }}>Role</Typography>
              <FormControl
                style={{
                  width: "200px",
                  marginTop: "2%",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                    borderRadius: "5px",
                }}
              >
                <Select
                  name="role"
                  value={role}
                  onChange={onRoleChange}
                  style={{ height: "40px",color:"#818E94", }}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="tester">Tester</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ flex: 1, marginTop: "3%" }}>
              <DialogActions
                style={{
                  display: "flex",
                  marginTop: "3%",
                  margin: "0vw 5vw",
                }}
              >
                <List onClick={() => handleFormSubmit()}>
                  <ListItem
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#4779EF",
                      borderRadius: "10px",
                      width: "18vw",
                      height: "40px",
                      fontSize: "15px",
                      justifyContent: "center",
                      marginBottom: "10px",
                      marginLeft: "-20px",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                  >
                    Create User
                  </ListItem>
                </List>
              </DialogActions>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
