import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { ThemePreferenceContext } from "../../../../index";

const Projects = () => {
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  return (
    <Box py={20} textAlign="center">
      <Typography
        variant="h2"
        style={{ color: currentTheme === "dark" ? "#FFFFFF" : "#000000" }}
      >
        Coming Soon....
      </Typography>
    </Box>
  );
};
export default Projects;
