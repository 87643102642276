import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import MetricUsage from "../Sessions/MetricUsage";
import AuthContext from "../../../../hooks/useAuth";
import profile from "../../../../asset/profile.png";
import time from "../../../../asset/time.png";
import calender from "../../../../asset/calender.png";
import { Divider } from "@mui/material";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Typography } from "@mui/material";
import "./filter.css";
import axios from "../../../../axios/index";
import "./Dashboard.css";
import ArticleIcon from "@mui/icons-material/Article";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";

const drawerWidth = 0;
const Styles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: 55,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: 55,
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    marginTop: "30px",
    padding: theme.spacing(5),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  emailicon: {
    display: "flex",
    alignItems: "left",
    flexWrap: "wrap",
    width: "50%",
  },
  userInfo: {
    display: "grid",
    gridTemplateColumns: "auto auto",
  },
  paper1: {
    padding: theme.spacing(2),
    marginTop: 20,
    color: theme.palette.text.secondary,
    height: 615,
  },
  paper2: {
    padding: theme.spacing(2),
    marginTop: 20,
    color: theme.palette.text.secondary,
    height: 130,
  },
  paper3: {
    padding: theme.spacing(2),
    marginTop: 20,
    color: theme.palette.text.secondary,
    height: 500,
  },
  paper4: {
    padding: theme.spacing(2),
    marginTop: 20,
    color: theme.palette.text.secondary,
    height: 150,
  },

  grids: {
    display: "flex",
    flexGrow: 1,
    "& div": {
      width: "100%",
      marginTop: "10px",
    },
  },
}));

export default function Sharing() {
  const classes = Styles();
  const [userId, setuserId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [appName, setAppName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [notes, setNotes] = useState("");
  const [data, setData] = useState("");
  useEffect(() => {
    const url = new URL(window.location.href);

    const path = url.search;
    const lastSlashIndex = path.lastIndexOf("=");
    const secondLastSlashIndex = path.lastIndexOf("=", lastSlashIndex - 1);
    const ThirdLastSlashIndex = path.lastIndexOf("=", secondLastSlashIndex - 1);
    const FourthLastSlashIndex = path.lastIndexOf("=", ThirdLastSlashIndex - 1);
    const user = path.substring(lastSlashIndex + 1);
    const ampersandIndex1 = path.indexOf("&", secondLastSlashIndex);
    const ampersandIndex2 = path.indexOf("&", ThirdLastSlashIndex);
    const ampersandIndex3 = path.indexOf("&", FourthLastSlashIndex);
    const sessionId = path.substring(secondLastSlashIndex + 1, ampersandIndex1);
    const app_name = path.substring(ThirdLastSlashIndex + 1, ampersandIndex2);
    const device_id = path.substring(FourthLastSlashIndex + 1, ampersandIndex3);
    console.log("User ID", user);
    console.log("Session Id  ", sessionId);
    console.log("App Name  ", app_name);
    console.log("Device Id  ", device_id);
    setuserId(user);
    setSessionId(sessionId);
    setAppName(app_name);
    setDeviceId(device_id);
    singleSessionItem();
    sessiondressdetails();
  }, [sessionId]);

  //Display session notes
  function sessiondressdetails(sessionid) {
    axios
      .get("/fetchallSessionNotes", {
        params: {
          sessionID: sessionId,
        },
      })
      .then((res) => {
        setNotes(res.data.data);
      });
  }

  function singleSessionItem() {

    axios
      .get(
        `http://gamemon-service.indiumsoft.com/shareSessionDetails?DeviceId=${deviceId}&appName=${appName}&sessionId=${sessionId}&userId=${userId}`
      )
      .then((res) => {
        setData(res.data.data[0]);
        // sessionStorage.setItem("currentSessionData", JSON.stringify(res.data.data[0]));
        // global.sessionname = data.sessionname;
        // global.deviceid = data.device_id;
        // global.userid = data.user_id;
        // global.appname = data.app_name;
        // global.sessionid = data.session_id;

        // setTimeout(() => {
        //   setSelectedDevice(data.sessionname);
        // }, 100);
      });
  }

  return (
    <>
      <main className={clsx(classes.content)}>
        <div className={classes.grids}>
          <div>
            <Grid container spacing={4} direction="column">
              <Grid item xs={10} md={10}>
                <>
                  <div className="device-info-style-list">
                    <List
                      style={{
                        maxHeight: "94%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <div
                        style={{
                          color: "#FFFFFF",
                          background: "#278EF1",
                          borderRadius: "8px",
                        }}
                      >
                        <ListItem button>
                          <PhoneAndroidIcon sx={{ fontSize: 30 }} />
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "white",
                                  width: "250px",
                                  marginLeft: "15px",
                                }}
                              >
                                {data?.sessionname}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="body"
                                sx={{
                                  color: "white",
                                  width: "250px",
                                  marginLeft: "15px",
                                  fontSize: "16px",
                                }}
                              >
                                Session Name
                              </Typography>
                            }
                          ></ListItemText>
                        </ListItem>
                      </div>
                    </List>
                  </div>

                  <div className="device-info-style-list">
                    <List
                      style={{
                        maxHeight: "94%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <div
                        style={{
                          color: "#FFFFFF",
                          background: "#278EF1",
                          borderRadius: "8px",
                        }}
                      >
                        <ListItem button>
                          <ArticleIcon sx={{ fontSize: 30 }} />
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                style={{
                                  color: "white",
                                  marginLeft: "15px",
                                }}
                              >
                                {data?.version_name}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="body"
                                style={{
                                  color: "white",
                                  width: "250px",
                                  marginLeft: "15px",
                                  fontSize: "16px",
                                }}
                              >
                                Version
                              </Typography>
                            }
                          ></ListItemText>
                        </ListItem>
                      </div>
                    </List>
                  </div>
                  <div className="device-info-style-list">
                    <List
                      style={{
                        maxHeight: "94%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <div
                        style={{
                          color: "#FFFFFF",
                          background: "#278EF1",
                          borderRadius: "8px",
                        }}
                      >
                        <ListItem button>
                          <DeveloperBoardIcon sx={{ fontSize: 30 }} />
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                style={{
                                  color: "white",
                                  marginLeft: "15px",
                                }}
                              >
                                {data?.gpu_vendor}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="body"
                                style={{
                                  color: "white",
                                  marginLeft: "15px",
                                  fontSize: "16px",
                                }}
                              >
                                Gpu Vendor
                              </Typography>
                            }
                          ></ListItemText>
                        </ListItem>
                      </div>
                    </List>
                  </div>
                </>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container spacing={4} direction="column">
              <Grid item xs={10} md={10}>
                <>
                  <div className="system-information">
                    <div className="app-info-sub">
                      <h2 className="device-para-style">System Information</h2>
                    </div>
                    <Divider />
                    <div className="app-info-sub">
                      <div>
                        <img src={profile} alt=" " className="app-info-image" />
                      </div>
                      <div className="device-info-text">
                        <p
                          style={{
                            marginTop: "-40px",
                            marginLeft: "25%",
                            fontWeight: "lighter",
                          }}
                        >
                          User
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "300",
                            marginTop: "-8px",
                            marginLeft: "25%",
                            color: "#278EF1",
                          }}
                        >
                          {data?.email}
                        </p>
                      </div>
                    </div>
                    <Divider />
                    <div className="app-info-sub">
                      <div>
                        <img src={time} alt=" " className="app-info-image" />
                      </div>
                      <div className="device-info-text">
                        <p
                          style={{
                            marginTop: "-40px",
                            marginLeft: "25%",
                            fontWeight: "lighter",
                          }}
                        >
                          Session Duration
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "300",
                            marginTop: "-8px",
                            marginLeft: "25%",
                            color: "#278EF1",
                          }}
                        >
                          {data?.total_duration}
                        </p>
                      </div>
                    </div>
                    <Divider />
                    <div className="app-info-sub">
                      <div>
                        <img
                          src={calender}
                          alt=" "
                          className="app-info-image"
                        />
                      </div>
                      <div className="device-info-text">
                        <p
                          style={{
                            marginTop: "-40px",
                            marginLeft: "25%",
                            fontWeight: "lighter",
                          }}
                        >
                          Session Date
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "300",
                            marginTop: "-8px",
                            marginLeft: "25%",
                            color: "#278EF1",
                          }}
                        >
                          {data?.created_at}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              </Grid>
            </Grid>

            <div>
              <Grid container spacing={4} direction="column">
                <Grid item xs={10} md={10}>
                  <>
                    <div className="system-informations">
                      <div className="app-info-sub">
                        <h2 className="device-para-style">Session Notes</h2>
                        {notes !== null && (
                          <div>
                            <div>
                              {notes &&
                                notes?.map((note) => (
                                  <div>
                                    <Divider />
                                    <br></br>
                                    <div
                                      style={{
                                        marginLeft: "20px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      {note?.session_notes_title}
                                    </div>
                                    <div style={{ marginLeft: "20px" }}>
                                      {note?.session_notes}
                                    </div>
                                  </div>
                                ))}{" "}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </Grid>
              </Grid>
            </div>
          </div>

          <Grid container spacing={4}>
            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/cpu" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.cpu_average_usage !== undefined
                      ? data?.cpu_average_usage
                      : 0
                  }
                  text="CPU Usage"
                  unit="%"
                  max={100}
                />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/gpu" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.gpu_average_usage !== undefined
                      ? data?.gpu_average_usage
                      : 0
                  }
                  text="GPU Usage"
                  unit="%"
                  max={100}
                />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/memory" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.memory_average_usage !== undefined
                      ? data?.memory_average_usage
                      : 0
                  }
                  text="Average Memory Usage"
                  unit="MB"
                  max={2048}
                />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/fps" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.average_fps_value !== undefined
                      ? data?.average_fps_value
                      : 0
                  }
                  text="FPS"
                  unit=""
                  max={100}
                />
                {/* </Link> */}
              </div>
            </Grid>

            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/MetricDownloadData" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.download_data_usage_average !== undefined
                      ? data?.download_data_usage_average
                      : 0
                  }
                  text="Total Data Downloaded"
                  unit="MiB"
                  max={100}
                />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/MetricUploadData" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.upload_data_usage_average !== undefined
                      ? data?.upload_data_usage_average
                      : 0
                  }
                  text="Total Data Uploaded"
                  unit="MiB"
                  max={1000}
                />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/power" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.peak_memory_value !== undefined
                      ? data?.peak_memory_value
                      : 0
                  }
                  text="Average Peak Memory"
                  unit="%"
                  max={2048}
                />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className="system-information">
                {/* <Link to="/Sessions/:id/MetricAppPower" style={{ textDecoration: "none" }}> */}
                <MetricUsage
                  value={
                    data?.fps_stability !== undefined ? data?.fps_stability : 0
                  }
                  text="FPS Stability"
                  unit="%"
                  max={100}
                />
                {/* </Link> */}
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </>
  );
}
