import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminMain from "./AdminMain.js";

export default function AdminHeader() {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Routes>
        <Route path="/" element={[<AdminMain open={open} />]} />
      </Routes>
    </div>
  );
}
