import React, { useState, createContext } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./hooks/useAuth";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from "styled-components";
import { base, light, dark } from "./theme";

export const themesMap = {
  light,
  dark,
};

const GlobalStyle = createGlobalStyle(
  ({ theme: { fonts, colors, lineHeights } }) => `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  a, a:visited {
    color: ${colors.primary};
  }
  body {
    background-color: ${colors.background}
  }
`
);

export const ThemePreferenceContext = createContext();

const THEME = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
      },
    },
  },

  typography: {
    fontFamily: [
      `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   sans-serif`,
    ].join(","),
  },
});

const ThemedApp = () => {
  const [currentTheme, setCurrentTheme] = useState("light");
  const theme = { ...base, colors: themesMap[currentTheme] };

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      <ThemePreferenceContext.Provider
        value={{ currentTheme, setCurrentTheme }}
      >
        <App />
      </ThemePreferenceContext.Provider>
    </StyledThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <MuiThemeProvider theme={THEME}>
        <ThemedApp />
      </MuiThemeProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
