import React, { useMemo, useState, useEffect, useContext, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";
import "./SessionCompare.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { ThemePreferenceContext } from "../../../../index";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import Divider from "@material-ui/core/Divider/Divider";
import { FormControl, InputAdornment } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ListItem from "@material-ui/core/ListItem";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@mui/material";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import BookIcon from "@mui/icons-material/Book";
import MenuItem from "@mui/material/MenuItem";
import { debounce } from "lodash";

const Plot = createPlotlyComponent(Plotly);

function SessionCompare() {
  const [allSession, setAllSession] = useState([]);
  const [selectedSession, setSelectedSession] = useState([]);
  const currentUserId = JSON.parse(
    sessionStorage.getItem("currentSessionData")
  );
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  var [arr, setArr] = useState([]);
  const auth = useContext(AuthContext);
  const userId = auth.id || currentUserId;
  const compData = auth.compData;
  const [selectedOption, setSelectedOption] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [filteredList, setFilteredList] = useState([]);
  const [AllSessionsActive, setAllSessionsActive] = useState(false);
  const [Allsessions, setAllsessions] = useState("");
  const [selecteditem, setSelecteditem] = React.useState([]);
  const [filterTab, setFilterTab] = useState(false);
  const [pageScroll, setPageScroll] = useState(1);
  const [appNameList, setAppNameList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [appName, setAppName] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const handleSelectOption = (event) => {
    setSelectedOption(event.target.value);
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);
  const [selectedTraceData, setselectedTraceData] = useState([]);
  const options = [
    "CPU Usage",
    "GPU Usage",
    "Avg Memory",
    "FPS",
    "Downloaded Data",
    "Uploaded Data",
    "AppPowerData",
  ];
  useEffect(() => {
    if (selectedOption === "GPU Usage") {
      setselectedTraceData(gpu);
    } else if (selectedOption === "CPU Usage") {
      setselectedTraceData(cpu);
    } else if (selectedOption === "Avg Memory") {
      setselectedTraceData(avgmemory);
    } else if (selectedOption === "FPS") {
      setselectedTraceData(fps);
    } else if (selectedOption === "Downloaded Data") {
      setselectedTraceData(downloadData);
    } else if (selectedOption === "Uploaded Data") {
      setselectedTraceData(upload_data);
    } else if (selectedOption === "AppPowerData") {
      setselectedTraceData(apppowerData);
    } else {
      setselectedTraceData(empty);
    }
  }, [selectedOption]);
  const session = compData?.map((item) => item.sessionname);
  const cpu_usage = compData?.map((item) => item.cpu_app_usage);
  const maxcpuvalues = cpu_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const mincpuvalues = cpu_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const cpu_time = compData?.map((item) => item.cpu_usage_time);
  const gpu_usage = compData?.map((item) => item.avg_gpu_usage);
  const maxgpuvalues = gpu_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const mingpuvalues = gpu_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const gpu_time = compData?.map((item) => item.gpu_usage_time);
  const memory_time = compData?.map((item) => item.memory_usage_time);
  const memory_usage = compData?.map((item) => item.avg_memory_usage);
  const maxmemoryvalues = memory_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minmemoryvalues = memory_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const upload_usage = compData?.map((item) => item.uploaddata_app_usage);
  const maxuploadvalues = upload_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minuploadvalues = upload_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const upload_time = compData?.map((item) => item.upload_data_usage_time);
  const downloadData_usage = compData?.map(
    (item) => item.downloadddata_app_uage
  );
  const maxddvalues = downloadData_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minddvalues = downloadData_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );
  const downloadData_time = compData?.map(
    (item) => item.download_data_usage_time
  );
  const fps_usage = compData?.map((item) => item.averagefps_app_usage);
  const fps_time = compData?.map((item) => item.average_fps_app_usage_time);
  const apppower_time = compData?.map((item) => item.stablityfps_app_time);
  const apppower_usage = compData?.map((item) => item.stablityfps_app_useage);
  const superuser = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
  const maxapppowervalues = apppower_usage?.map((subArray) =>
    Math?.max(...(subArray ?? []))
  );
  const minapppowervalues = apppower_usage?.map((subArray) =>
    Math?.min(...(subArray ?? []))
  );

  const empty = [];
  for (let i = 0; i < gpu_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const max = Math.max(repeatedData.length);
    const trace1 = {
      x: repeatedData,
      y: superuser,
      name: session[i],
      hovertemplate: "<b>GPU : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace1.marker.color = colors[i];
    }
    empty.push(trace1);
  }

  const gpu = [];
  for (let i = 0; i < gpu_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const max = Math.max(repeatedData.length);
    const trace1 = {
      x: repeatedData,
      y: gpu_usage[i],
      name: session[i],
      hovertemplate: "<b>GPU : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace1.marker.color = colors[i];
    }
    gpu.push(trace1);
  }
  const cpu = [];
  for (let i = 0; i < cpu_time?.length; i++) {
    const repeatedData = Array.from(
      { length: cpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: cpu_usage[i],
      name: session[i],
      hovertemplate: "<b>CPU : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = ("hi", colors[i]);
    }
    cpu.push(trace2);
  }

  const avgmemory = [];
  for (let i = 0; i < memory_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: memory_usage[i],
      name: session[i],
      hovertemplate: "<b>Avg Memory : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = colors[i];
    }
    avgmemory.push(trace2);
  }

  const upload_data = [];
  for (let i = 0; i < upload_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: upload_usage[i],
      name: session[i],
      hovertemplate: "<b>Upload Data : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = colors[i];
    }
    upload_data.push(trace2);
  }

  const fps = [];
  for (let i = 0; i < fps_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace2 = {
      x: repeatedData,
      y: fps_usage[i],
      name: session[i],
      hovertemplate: "<b>FPS : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace2.marker.color = colors[i];
    }
    fps.push(trace2);
  }

  const downloadData = [];
  for (let i = 0; i < downloadData_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace = {
      x: repeatedData,
      y: downloadData_usage[i],
      name: session[i],
      hovertemplate: "<b>Download Data : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace.marker.color = colors[i];
    }
    downloadData.push(trace);
  }

  const apppowerData = [];
  for (let i = 0; i < apppower_time?.length; i++) {
    const repeatedData = Array.from(
      { length: gpu_time[i]?.length },
      (_, index) => index
    );
    const trace = {
      x: repeatedData,
      y: apppower_usage[i],
      name: session[i],
      hovertemplate: "<b>App Power : %{y}(%)<br>Time : %{x}(secs)</br></b>",
      type: "scatter",
      line: { shape: "spline" },
      marker: { size: "3" },
    };
    const colors = [
      "#8300DB",
      "#0B46D3",
      "#4779EF",
      "#FF3A29",
      "#FF9100",
      "#FEC400",
      "#35DB41",
      "#01880B",
    ];
    if (i < colors.length) {
      trace.marker.color = colors[i];
    }
    apppowerData.push(trace);
  }

  function Sessions(pageNum) {
    axios
      .get("/mySessions", {
        params: {
          userId: userId,
          page: pageNum,
          size: 50,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.data?.length) {
          setAllSession((prevRes) => [...prevRes, ...res.data.data]);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log(error); // Empty array when API returns 404 status code
        }
      });
  }

  useEffect(() => {
    Sessions(pageScroll);
  }, []);

  const onSelectionChanged = (e, index, sessionId) => {
    if (selecteditem.length <= 8) {
      setSelecteditem((prevSelectedItems) => {
        if (prevSelectedItems.includes(index)) {
          return prevSelectedItems.filter((item) => item !== index);
        } else {
          return [...prevSelectedItems, index];
        }
      });
    } else {
      setSelecteditem((prevSelectedItems) => {
        return prevSelectedItems.filter((item) => item !== index);
      });
    }

    if (selecteditem.includes(index)) {
      let list = selectedSession.filter(
        (item) => item.session_id !== sessionId
      );
      setSelectedSession(list);
    } else {
      setSelectedSession((prevSelectedSession) => [...prevSelectedSession, e]);
    }
  };

  useEffect(() => {
    setArr([]);
    for (let i = 0; i < selectedSession?.length; i++) {
      setArr((ps) => [...ps, selectedSession[i].session_id]);
    }
  }, [selectedSession]);

  const compareSession = () => {
    if (selecteditem?.length <= 1) {
      if (!toastVisible.current) {
        toast.dismiss();
        toastVisible.current = true;
        toast.error("you have to select at least 2 sessions to compare!", {
          position: toast.POSITION.BOTTOM_CENTER,
          onClose: () => {
            toastVisible.current = false;
          },
        });
      }
    } else if (selecteditem?.length > 8) {
      if (!toastVisible.current) {
        toast.dismiss();
        toastVisible.current = true;
        toast.error("You can only select up to 8 sessions!", {
          position: toast.POSITION.BOTTOM_CENTER,
          onClose: () => {
            toastVisible.current = false;
          },
        });
      }
    } else {
      axios
        .get("/compareSessions", {
          params: {
            userId: userId,
            s1: arr[0] ? arr[0] : "",
            s2: arr[1] ? arr[1] : "",
            s3: arr[2] ? arr[2] : "",
            s4: arr[3] ? arr[3] : "",
            s5: arr[4] ? arr[4] : "",
            s6: arr[5] ? arr[5] : "",
            s7: arr[6] ? arr[6] : "",
            s8: arr[7] ? arr[7] : "",
          },
          headers: { Authorization: `Bearer ${auth.token}` },
        })
        .then((res) => {
          auth.compDataHandler(res.data.data);
          // navigate("/SessionComparison/details");
        });
    }
  };

  const toastVisible = useRef(false);
  useEffect(() => {
    if (selecteditem.length && arr.length) {
      compareSession();
    }
  }, [selecteditem, arr]);

  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    setAllSession(savedValues?.allSession);
    // setSelectedSession(savedValues?.selectedSession);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      allSession,
      selectedSession,
    };
    window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const rowDatas = compData?.map((session, index) => {
    return {
      app_name: session?.app_name,
      sessionname: session?.sessionname,
      average_fps_value: session?.average_fps_value,
      cpu_average_usage: session?.cpu_average_usage,
      maxcpuvalue: maxcpuvalues[index],
      mincpuvalue: mincpuvalues[index],
      device_name: session?.device_name,
      fps_stability: isNaN(session?.fps_stability) ? 0 : session?.fps_stability,
      gpu_average_usage: session?.gpu_average_usage,
      maxgpuvalue: maxgpuvalues[index],
      mingpuvalue: mingpuvalues[index],
      memory_average_usage: session?.memory_average_usage,
      maxmemoryvalues: maxmemoryvalues[index],
      minmemoryvalues: minmemoryvalues[index],
      peak_memory_value: session?.peak_memory_value,
      upload_data_usage_average: session?.upload_data_usage_average,
      maxuploadvalues: maxuploadvalues[index],
      minuploadvalues: minuploadvalues[index],
      created_at: session?.created_at,
      download_data_usage_average: session?.download_data_usage_average,
      maxddvalues: maxddvalues[index],
      minddvalues: minddvalues[index],
      cpu_app_usage: session?.cpu_app_usage,
      maxapppowervalues: maxapppowervalues[index],
      minapppowervalues: minapppowervalues[index],
    };
  });

  const columnDefss = [
    {
      field: "app_name",
      headerName: "Application",
      unSortIcon: true,
      width: 280,
      cellClass: "center-align",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
    },
    {
      field: "device_name",
      headerName: "Device",
      unSortIcon: true,
      cellClass: "center-align",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      field: "sessionname",
      headerName: "Session Name",
      unSortIcon: true,
      cellClass: "center-align",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      field: "average_fps_value",
      headerName: "FPS",
      unSortIcon: true,
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
    },
    {
      field: "fps_stability",
      headerName: "FPS Stability",
      unSortIcon: true,
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
    },
    {
      field: "cpu_average_usage",
      headerName: "CPU",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const cpuUsage = params.data.cpu_average_usage;
        const maxCpuValue = params.data.maxcpuvalue;
        const minCpuValue = params.data.mincpuvalue;
        return `${cpuUsage} <br />Min: ${minCpuValue} - Max: ${maxCpuValue}`;
      },
    },
    {
      field: "gpu_average_usage",
      headerName: "GPU",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const gpuUsage = params.data.gpu_average_usage;
        const maxgpuValue = params.data.maxgpuvalue;
        const mingpuValue = params.data.mingpuvalue;
        return `${gpuUsage} <br />Min: ${mingpuValue} - Max: ${maxgpuValue}`;
      },
    },
    {
      field: "memory_average_usage",
      headerName: "Memory",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const memory_average_usage = params.data.download_data_usage_average;
        const maxmemoryvalues = params.data.maxmemoryvalues;
        const minmemoryvalues = params.data.minmemoryvalues;
        return `${memory_average_usage} <br />Min: ${minmemoryvalues} - Max: ${maxmemoryvalues}`;
      },
    },
    // { field: "peak_memory_value", headerName: "Peak Memory", unSortIcon: true },
    {
      field: "download_data_usage_average",
      headerName: "Download Data",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const download_data_usage_average =
          params.data.download_data_usage_average;
        const maxddvalues = params.data.maxddvalues;
        const minddvalues = params.data.minddvalues;
        return `${download_data_usage_average} <br />Min: ${minddvalues} - Max: ${maxddvalues}`;
      },
    },
    {
      field: "upload_data_usage_average",
      headerName: "Upload Data",
      unSortIcon: true,
      cellClass: "space-reduce",
      cellStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
      },
      headerClass: currentTheme === "dark" ? "header-dark" : "header-light",
      cellRenderer: (params) => {
        const upload_data_usage_average = params.data.upload_data_usage_average;
        const maxuploadvalues = params.data.maxuploadvalues;
        const minuploadvalues = params.data.minuploadvalues;
        return `${upload_data_usage_average} <br />Min: ${minuploadvalues} - Max: ${maxuploadvalues}`;
      },
    },
    // { field: "created_at", headerName: "Date", unSortIcon: true },
  ];

  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    setAllSession(savedValues?.allSession);
    setSelectedOption(savedValues?.selectedOption);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      allSession,
      selectedOption,
    };
    window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  useEffect(() => {
    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  }, [allSession]);

  const handleChangeSearch = (event) => {
    let val = event.target.value;
    setShowClearIcon(val === "" ? "none" : "flex");
    setSearchText(val);

    const filteredItems = allSession.filter((list) =>
      list.app_name.toLowerCase().includes(val.toLowerCase())
    );

    setFilteredList(filteredItems);
  };

  const handleChangeApp = (event) => {
    const {
      target: { value },
    } = event;

    setAppName(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeSession = (event) => {
    const {
      target: { value },
    } = event;

    setSessionName(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeDevice = (event) => {
    const {
      target: { value },
    } = event;

    setDeviceName(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleClickRemoveText = () => {
    setSearchText("");
    setShowClearIcon("none");

    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  };

  const handleFilterTab = () => {
    setFilterTab(!filterTab);
  };

  const handleCloseFilter = () => {
    setFilterTab(false);
  };

  const handleApplyFilter = () => {
    const uniqueSessionNames = new Set();
    const filteredItems = [];
    if (
      appName.length === 0 &&
      sessionName.length === 0 &&
      deviceName.length === 0
    ) {
      toast.error("Choose at least 1 to Apply Filter!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    allSession.forEach((item) => {
      const matchesAppname =
        appName.length === 0 || appName.includes(item.app_name);
      const matchesSessionName =
        sessionName.length === 0 || sessionName.includes(item.sessionname);
      const matchesDeviceName =
        deviceName.length === 0 || deviceName.includes(item.device_name);

      if (matchesAppname && matchesSessionName && matchesDeviceName) {
        if (!uniqueSessionNames.has(item.sessionname)) {
          uniqueSessionNames.add(item.sessionname);
          filteredItems.push(item);
        }
      }
    });

    setFilteredList(filteredItems);
    setFilterTab(false);
  };

  const handleAddBookmark = (e, sessionId) => {
    e.stopPropagation();
    const values = JSON.stringify({
      session_id: sessionId,
      user_id: userId,
    });
    axios
      .post("/bookmark", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        {
          const errorMessage = "Bookmark Added !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteBookmark = (e, sessionId) => {
    e.stopPropagation();
    axios
      .post("/removeBookmark", {
        params: {
          session_id: sessionId,
          user_id: userId,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        {
          const errorMessage = "Bookmark Deleted!";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteSession = (sessionId) => {
    setAllSession([]);
    axios
      .delete("/deleteSession", {
        params: {
          sessionId: sessionId,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status) {
          Sessions(pageScroll);
        }
        {
          const errorMessage = "Session Deledted Successfully !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast.error("Deletion Failed !!!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
  };

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom) {
      setPageScroll((prevRes) => prevRes + 1);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 400);

  useEffect(() => {
    if (pageScroll > 1) {
      Sessions(pageScroll);
    }
  }, [pageScroll]);

  function getAllFilterList() {
    const distinctValuesApp = new Set();
    const distinctValuesSession = new Set();
    const distinctValuesDevice = new Set();
    let appList = [];
    let sessionList = [];
    let deviceList = [];

    for (const obj of allSession) {
      const valueApp = obj["app_name"];
      const valueSession = obj["sessionname"];
      const valueDevice = obj["device_name"];

      if (!distinctValuesApp.has(valueApp)) {
        distinctValuesApp.add(valueApp);
        appList.push(valueApp);
      }
      if (!distinctValuesSession.has(valueSession)) {
        distinctValuesSession.add(valueSession);
        sessionList.push(valueSession);
      }
      if (!distinctValuesDevice.has(valueDevice)) {
        distinctValuesDevice.add(valueDevice);
        deviceList.push(valueDevice);
      }
    }

    setAppNameList(appList);
    setSessionList(sessionList);
    setDeviceList(deviceList);
  }

  useEffect(() => {
    if (allSession?.length) {
      getAllFilterList();
    }
  }, [allSession]);

  const isNotEnoughSessionsSelected = selecteditem.length < 2;

  return (
    <>
      <div
        style={{
          marginTop: "50px",
          padding: "16px",
        }}
      >
        {/* left */}
        <div
          style={{
            display: "flex",
            float: "left",
            position: "fixed",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormControl>
              <TextField
                size="small"
                variant="outlined"
                value={searchText}
                placeholder={"Search"}
                onChange={handleChangeSearch}
                InputProps={{
                  style: {
                    background: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                    border:
                      currentTheme === "dark"
                        ? "1px solid #646667"
                        : "1px solid #DFDFDF",
                    color: currentTheme === "dark" ? "#FFFFFF" : "#292E33",
                    width: "21vw",
                    borderRadius: "5px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: showClearIcon }}
                      onClick={handleClickRemoveText}
                    >
                      <ClearIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                background: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                boxShadow: "0px 2px 6px #29489803",
                borderRadius: "5px",
              }}
              onClick={handleFilterTab}
            >
              <TuneIcon
                sx={{
                  fontSize: 30,
                  color: currentTheme === "dark" ? "#FFFFFF" : "",
                  cursor: "pointer",
                }}
                className="tuneIcon"
              />
            </div>
          </div>
          <div
            value={currentTheme}
            onChange={(e) => {
              setCurrentTheme(e.target.value);
            }}
            style={{
              display: AllSessionsActive != 404 ? "block" : "none",
              marginTop: "1.5%",
              cursor: "pointer",
              width: "25vw",
              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              borderRadius: "3px",
              height: "78vh",
              overflowY: "auto",
              overflowX: "hidden",
              scrollBehavior: "smooth",
              boxShadow: "0px 2px 6px #0000000A",
            }}
            className="ScrollBar"
            onScroll={(e) => debouncedHandleScroll(e)}
          >
            {/* List of sessions */}
            {!filterTab ? (
              filteredList?.map((data, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    setAllsessions(e.target.textContent);
                    setAllSessionsActive(!AllSessionsActive);
                    onSelectionChanged(data, i, data.session_id);
                  }}
                  style={{
                    background: selecteditem.includes(i)
                      ? "transparent linear-gradient(180deg, #6398F5 0%, #4475EE 100%) 0% 0% no-repeat padding-box"
                      : currentTheme === "dark"
                      ? "#292E33"
                      : "white",
                    color: selecteditem.includes(i)
                      ? "white"
                      : currentTheme === "dark"
                      ? "white"
                      : "black",
                    fontSize: "20px",
                    borderRadius: "8px",
                    marginLeft: "5px",
                    marginBottom: "-4px",
                    width: "97%",
                  }}
                >
                  {/* Session Information */}
                  <ListItem>
                    {data.device_name === "apple" ? (
                      <AppleIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    ) : (
                      <AndroidIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    )}
                    <ListItemText>
                      <div
                        // variant="body"
                        style={{
                          fontWeight: "bold",
                          marginLeft: "15px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: selecteditem.includes(i)
                            ? "white"
                            : currentTheme === "dark"
                            ? "white"
                            : "#4779EF",
                        }}
                        title={data?.app_name}
                        className="sess-appname"
                      >
                        {data?.app_name}
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "capitalize",
                          marginLeft: "15px",
                          color: selecteditem.includes(i)
                            ? "white"
                            : currentTheme === "dark"
                            ? "#C5CACF"
                            : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        {data.device_name}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginLeft: "15px",
                          color: selecteditem.includes(i)
                            ? "white"
                            : currentTheme === "dark"
                            ? "#C5CACF"
                            : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        Time Played{" "}
                        <span
                          style={{
                            color: selecteditem.includes(i)
                              ? "white"
                              : currentTheme === "dark"
                              ? "#C5CACF"
                              : "#494949",
                            fontWeight: "600",
                          }}
                          id="scroll-fonts"
                        >
                          {data.session_duration}
                        </span>
                      </Typography>
                    </ListItemText>
                    <div
                      style={{
                        marginBottom: "2vh",
                        display: "flex",
                        gap: "10%",
                      }}
                    >
                      {selecteditem.includes(i) ? (
                        <DeleteOutlineOutlinedIcon
                          className="scroll-icon"
                          onClick={() => handleDeleteSession(data.session_id)}
                        />
                      ) : (
                        ""
                      )}
                      {data.is_bookmarked === "true" ? (
                        <BookIcon
                          className="scroll-icon"
                          onClick={(e) =>
                            handleDeleteBookmark(e, data.session_id)
                          }
                        />
                      ) : (
                        <BookOutlinedIcon
                          className="scroll-icon"
                          onClick={(e) => handleAddBookmark(e, data.session_id)}
                        />
                      )}
                    </div>
                  </ListItem>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                      margin: "5px 0",
                    }}
                  />
                </div>
              )) // Filter tab
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "5%",
                }}
              >
                <h4
                  style={{
                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                                sans-serif`,
                    color: currentTheme === "dark" ? "#A2B1C2" : "#494949",
                    fontSize: "14px",
                  }}
                >
                  Filters:
                </h4>
                <FormControl sx={{ m: 5, width: 600 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "4%",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{ paddingTop: "9%" }}>
                      <InputLabel
                        id="demo-multiple-name-label"
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                          fontSize: "14px",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                      >
                        Device
                      </InputLabel>
                      <Select
                        multiple
                        value={deviceName}
                        onChange={handleChangeDevice}
                        input={<OutlinedInput />}
                        // MenuProps={MenuProps}
                        style={{
                          width: "22vw",
                          height: "6vh",
                          color: currentTheme === "dark" ? "#818E94" : "black",
                        }}
                      >
                        {deviceList.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            // style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div style={{ paddingTop: "9%" }}>
                      <InputLabel
                        id="demo-multiple-name-label"
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                          fontSize: "14px",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                  sans-serif`,
                        }}
                      >
                        App Name
                      </InputLabel>
                      <Select
                        multiple
                        value={appName}
                        onChange={handleChangeApp}
                        input={<OutlinedInput />}
                        // MenuProps={MenuProps}
                        style={{
                          width: "22vw",
                          height: "6vh",
                          color: currentTheme === "dark" ? "#818E94" : "black",
                        }}
                      >
                        {appNameList.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            // style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div style={{ paddingTop: "9%" }}>
                      <InputLabel
                        id="demo-multiple-name-label"
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                          fontSize: "14px",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                                sans-serif`,
                        }}
                      >
                        Session
                      </InputLabel>
                      <Select
                        multiple
                        value={sessionName}
                        onChange={handleChangeSession}
                        input={<OutlinedInput />}
                        // MenuProps={MenuProps}
                        style={{
                          width: "22vw",
                          height: "6vh",
                          color: currentTheme === "dark" ? "#818E94" : "black",
                        }}
                      >
                        {sessionList.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            // style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      marginTop: "25vh",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        width: "180px",
                        height: "48px",
                        borderRadius: "40px",
                        marginTop: "-10vh",
                        background:
                          "transparent linear-gradient(180deg, #659AF5 0%, #4374EE 100%) 0% 0% no-repeat padding-box",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                        sans-serif`,
                      }}
                      onClick={handleApplyFilter}
                    >
                      Apply Filters
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        width: "180px",
                        height: "48px",
                        borderRadius: "40px",
                        marginTop: "-10vh",
                        color: currentTheme === "dark" ? "#FFFFFF" : "#494949",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                        sans-serif`,
                      }}
                      onClick={handleCloseFilter}
                    >
                      Cancel
                    </Button>
                  </div>
                </FormControl>
              </div>
            )}
          </div>
        </div>

        {/* right */}
        <div style={{ marginLeft: "10%" }}>
          <h5
            style={{
              marginTop: "-1%",
              marginLeft: "19.5vw",
              color: currentTheme === "dark" ? "white" : "black",
            }}
            className="title"
          >
            Sessions Comparison
          </h5>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  borderRadius: "10px",
                  padding: "10px",
                  marginLeft: "19vw",
                }}
              >
                <div style={{ width: "50vw", height: "auto" }}>
                  <div style={{ height: "200px", boxSizing: "border-box" }}>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "0px",
                        boxShadow: "0px 25px 30px #0000000A",
                        border:
                          currentTheme === "dark"
                            ? "1px solid #646667"
                            : "1px solid #DFDFDF",
                      }}
                      className={
                        currentTheme === "dark"
                          ? "ag-theme-alpine-dark"
                          : "ag-theme-alpine"
                      }
                    >
                      <AgGridReact
                        rowData={isNotEnoughSessionsSelected ? [] : rowDatas}
                        columnDefs={columnDefss}
                        rowSelection="multiple"
                        defaultColDef={defaultColDef}
                        rowMultiSelectWithClick={true}
                        rowHeight={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  width: "50vw",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  marginLeft: "19.5vw",
                }}
              >
                <div style={{ display: "flex" }}>
                  <h5
                    style={{
                      float: "left",
                      marginLeft: "2%",
                      marginTop: "1.5%",
                      color: currentTheme === "dark" ? "white" : "black",
                    }}
                    className="title"
                  >
                    Sessions Comparison Chart
                  </h5>
                  <select
                    style={{
                      marginTop: "1%",
                      float: "right",
                      width: "200px",
                      height: "30px",
                      backgroundColor:
                        currentTheme === "dark" ? "#363C43" : "#F2F2F2",
                      borderRadius: "5px",
                      color: currentTheme === "dark" ? "white" : "black",
                      fontSize: "14px",
                      fontWeight: "400",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                    className="select-metrics"
                    value={selectedOption}
                    onChange={handleSelectOption}
                  >
                    <option value="">Select Metrics</option>
                    {options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <Divider style={{ width: "100%" }} />
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Plot
                       data={isNotEnoughSessionsSelected ? [] : selectedTraceData}
                      layout={{
                        X: 0,
                        xanchor: "left",
                        height: 260,
                        width: 590,
                        margin: { l: 70, r: 40, b: 95, t: 40, pad: 15 },
                        xref: 450,
                        text: selectedOption,
                        font: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        },
                        borderRadius: 20,
                        showTips: true,

                        xaxis: {
                          gridcolor: "gray",
                          zeroline: false,
                          line: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          tickfont: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          autorange: true,
                          linecolor:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          showgrid: true,
                          title: {
                            text: "Time (secs)",
                            font: {
                              family:
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                              size: 14,
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            },
                          },
                        },

                        yaxis: {
                          gridcolor: "gray",
                          zeroline: false,
                          line: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          tickfont: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          },
                          autorange: true,
                          linecolor:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          showgrid: true,
                          title: {
                            text: `${selectedOption}`,
                            font: {
                              family:
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                              size: 14,
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            },
                          },
                        },
                        plot_bgcolor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        paper_bgcolor:
                          currentTheme === "dark" ? "#292E33" : "white",
                      }}
                      config={{ displayModeBar: false, scrollZoom: false }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          toastStyle={{
            marginTop: "4rem",
            borderRadius: "10px",
            backgroundColor: "white",
            alignContent: "center",
            height: 50,
            margin: 10,
            width: 300,
            closeOnClick: true,
            textAlign: "center",
          }}
          closeButton={false}
          hideProgressBar={false}
          autoClose={2000}
          position="top-center"
          className="toast-container"
          toastClassName="dark-toast"
        />
      </div>
    </>
  );
}

export default SessionCompare;
