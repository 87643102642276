import React, { useState, useEffect, useContext } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { ThemePreferenceContext } from "../../../../index";
import "./MetricUsage.css";

function MetricUsage(props) {
  const [colorProg, setColorProg] = useState("#F5F6FA");
  const [isActive, setIsActive] = useState(false);
  const progress = (props.value * 100) / props.max;
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  useEffect(() => {
    if (progress <= 25) {
      setColorProg("#FF3A29");
    } else if (progress < 60) {
      setColorProg("#FEC400");
    } else if (progress > 75) {
      setColorProg("#2FB739");
    }
  }, [progress]);

  var BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    width: "56%",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorProg,
    },
  }));

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className={`metric-usage ${isActive ? "active" : ""} ${
        props.selected ? "selected" : ""
      }`}
      onClick={handleClick}
      style={{
        backgroundColor: currentTheme === "dark" ? "#363C43" : "#FEFEFF",
        color:
          props.selected || isActive
            ? "white"
            : currentTheme === "dark"
            ? "#FFFFFF"
            : "#000000",
        fontWeight: 400,
      }}
    >
      <p>{props.text}</p>
      <div style={{ display: "flex" }}>
        <BorderLinearProgress variant="determinate" value={progress} />
        <small
          style={{
            marginLeft: "10px",
            marginTop: "-5px",
            color:
              props.selected || isActive
                ? "white"
                : currentTheme === "dark"
                ? "#ffffff"
                : "#000000",
          }}
        >
          {props.value + " "}
          {props.unit}
        </small>
      </div>
    </div>
  );
}

export default MetricUsage;
