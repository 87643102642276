import React, { useState } from "react";
import "./Layout.css";

const UserTabLayout = ({ menuItems, defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || null);

  const handleTabClick = (tabName, disableClick) => {
    setActiveTab(tabName);
  };

  return (
    <div className="layout">
      <div className="menu">
        {menuItems.map((menuItem) => (
          <div
            key={menuItem.name}
            className={`menu-item ${
              activeTab === menuItem.name ? "active" : ""
            }`}
            onClick={() => handleTabClick(menuItem.name)}
          >
            {menuItem.label}
          </div>
        ))}
      </div>
      <div className="content">
        {menuItems.map((menuItem) => (
          <div
            key={menuItem.name}
            className={`tab-content ${
              activeTab === menuItem.name ? "active" : ""
            }`}
          >
            {activeTab === menuItem.name && menuItem.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserTabLayout;
