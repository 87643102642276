import React, { useContext, useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AndroidIcon from "@mui/icons-material/Android";
import { Typography } from "@mui/material";
import "./OrganizationDashboard.css";
import "./Dashboard.css";
import { StylesProvider } from "@material-ui/core/styles";
import { format, parseISO } from "date-fns";
import { ThemePreferenceContext } from "../../../../index";
import SessionMain from "../Sessions/Sessions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AppleIcon from "@mui/icons-material/Apple";

var mapObj = {
  com: " ",
  ".oneplus": " ",
  ".qualcomm": " ",
  ".android": " ",
  ".display": " ",
  ".google": " ",
  ".tools": " ",
  ".internal": " ",
  ".emulation": " ",
  ".network": " ",
  ".dragonfistztamilan": " ",
};

export default function OrganizationDashboard() {
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const auth = useContext(AuthContext);
  const role = auth.role;
  const currentUserId = JSON.parse(
    sessionStorage.getItem("currentSessionData")
  );
  const uid = auth.id || currentUserId;
  const [sessionNoteTitle, setsessionNoteTitle] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const options = [
    "CPU Usage",
    "GPU Usage",
    "Avg Memory",
    "FPS",
    "Downloaded Data",
    "Uploaded Data",
    "AppPowerData",
  ];
  const handleSelectOption = (event) => {
    setSelectedOption(event.target.value);
  };
  const [Firstsessiondata, SetFirstsessiondata] = React.useState([]);
  const [Secondsessiondata, SetSecondsessiondata] = React.useState([]);
  const [selecteditem, setSelecteditem] = React.useState("yellow");
  const [ApplicationActive, setApplicationActive] = useState(false);
  const [DevicesActive, setDevicesActive] = useState(false);
  const [UserActive, setUserActive] = useState(false);
  const [SessionsActive, setSessionsActive] = useState(false);
  const [DateActive, setDateActive] = useState(false);
  const [User, setUser] = useState("");
  const [application, setApplication] = useState("");
  const [device, setDevices] = useState("");
  const [sessions, setSessions] = useState("");
  const [userName, setUserName] = useState("");
  const [notes, setNotes] = useState("");
  const [date, setDate] = useState("Date");
  const [FirstUserdata, SetFirstUserdata] = React.useState([]);
  const [Firstdevicedata, SetFirstdevicedata] = React.useState([]);
  const [Seconddevicedata, SetSeconddevicedata] = React.useState([]);
  const [selectedUseritem, setSelectedUseritem] = React.useState("yellow");
  const [selectedDeviceitem, setSelectedDeviceitem] = React.useState("yellow");
  const [selectedDevice, setSelectedDevice] = React.useState("");
  const [selectedsessionitem, setSelectedApplicationitem] = React.useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [AllSessionsActive, setAllSessionsActive] = useState(false);
  const [Allsessions, setAllsessions] = useState("");
  const [allSession, setAllSession] = useState([]);
  const [topPerformer, setTopPerformer] = useState([]);
  const [pageScroll, setPageScroll] = useState(1);
  const sortedSessions = allSession?.sort(
    (a, b) => new Date(b.session_date) - new Date(a.session_date)
  );
  const [calls, setcall] = useState(uid);

  React.useEffect(() => {
    let isMount = true;
    axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (isMount) {
          SetFirstUserdata(res.data.data);
        }
      })
      .catch((err) => {});
    return () => {
      isMount = false;
    };
  }, []);

  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setUserActive(false);
      setDevicesActive(false);
      setApplicationActive(false);
      setSessionsActive(false);
      setDateActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  React.useEffect(() => {
    let isMount = true;
    if (uid && startDate && endDate) {
      axios
        .get("/devices", {
          params: {
            userId: uid,
            fromDate: startDate,
            toDate: endDate,
          },
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((res) => {
          if (isMount) {
            SetFirstdevicedata(res.data.data);
          }
          global.device_name = res.data.device_name;
        })
        .catch((err) => {});
    }
    return () => {
      isMount = false;
    };
  }, [startDate, endDate]);

  function formatDate(dateString) {
    const date = parseISO(dateString); // Parse the date string into a Date object
    return format(date, "dd-MMM-yyyy"); // Format the date as "dd-mon-yyyy"
  }

  //Display available User
  function singleUserItem(e, data, id) {
    setSelectedUseritem(id);
    axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {});
  }

  //Display available Application
  function singleDeviceItem(e, data, id) {
    setSelectedDeviceitem(id);
    axios
      .get("/applications", {
        params: {
          deviceId: data.device_id,
          userId: uid,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetSeconddevicedata(res.data.data);
      });
  }

  //All Session function
  function singleApplicationItem(e, data, id) {
    setSelectedApplicationitem(id);
    axios
      .get("/allSessions", {
        params: {
          deviceId: data.device_id,
          userId: uid,
          appName: data.app_name,
          fromDate: startDate,
          toDate: endDate,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetFirstsessiondata(res.data.data);
      });
  }

  useEffect(() => {
    Sessions(pageScroll);
    TopFerformer();
  }, [calls]);

  function Sessions(pageNum) {
    axios
      .get("/orgRecentSessions", {
        params: {
          userId: calls,
          page: pageNum,
          size: 50,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          setAllSession([]);
        } else {
          if (res?.data?.data?.length) {
            setAllSession((prevRes) => [...prevRes, ...res.data.data]);
          }
          singleSessionItem();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log(error);
        }
      });
  }

  function TopFerformer() {
    axios
      .get("/topPerformers", {
        params: {
          userId: calls,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          setTopPerformer([]);
        } else {
          setTopPerformer(res.data.data);
          singleSessionItem();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setTopPerformer([]);
        }
      });
  }

  //Display specfic session details
  function singleSessionItem(e, data, id) {
    setSelecteditem(id);
    axios
      .get("/sessionDetails", {
        params: {
          DeviceId: data.device_id,
          appName: data.app_name,
          userId: uid,
          sessionId: data.session_id,
          createdDate: data.session_date,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetSecondsessiondata(res.data.data[0]);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data[0])
        );
        global.sessionname = data.sessionname;
        global.deviceid = data.device_id;
        global.userid = data.user_id;
        global.appname = data.app_name;
        global.sessionid = data.session_id;
        global.createdDate = data.session_date;
        setTimeout(() => {
          setSelectedDevice(data.sessionname);
        }, 100);
      });
  }

  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    SetFirstdevicedata(savedValues?.Firstdevicedata);
    setsessionNoteTitle(savedValues?.sessionNoteTitle);
    SetSeconddevicedata(savedValues?.Seconddevicedata);
    SetFirstsessiondata(savedValues?.Firstsessiondata);
    SetSecondsessiondata(savedValues?.Secondsessiondata);
    setNotes(savedValues?.notes);
    setStartDate(savedValues?.startDate);
    setEndDate(savedValues?.endDate);
    setDevices(savedValues?.device);
    setApplication(savedValues?.application);
    setSessions(savedValues?.sessions);
    setUserName(savedValues?.user_name);
    setUser(savedValues?.User);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      Firstdevicedata,
      sessionNoteTitle,
      Seconddevicedata,
      Firstsessiondata,
      Secondsessiondata,
      date,
      device,
      notes,
      application,
      sessions,
      startDate,
      endDate,
      User,
      userName,
    };
    // window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom) {
      setPageScroll((prevRes) => prevRes + 1);
    }
  };

  useEffect(() => {
    if (pageScroll > 1) {
      Sessions(pageScroll);
    }
  }, [pageScroll]);

  const remainingPercentage = 56;
  const usedPercentage = 30;

  return (
    <>
      <div
        style={{
          width: "82vw",
          position: "fixed",
          height: "85vh",
        }}
      >
        <main>
          <div style={{ margin: 10 }}>
            <div
              style={{
                display: "flex",
                position: "relative",
                marginTop: "1%",
                justifyContent: "space-between",
                marginLeft: "-1vw",
              }}
            >
              <div style={{ textAlign: "start" }}>
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    paddingBottom: "3px",
                    marginBottom: "5px",
                  }}
                  className="cards-title"
                >
                  Top Performers
                </p>
                <div
                  style={{
                    width: "15vw",
                    height: "25vh",
                    backgroundColor:
                      currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "10px",
                    boxShadow: "0px 3px 6px #0000000A",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollBehavior: "smooth",
                  }}
                  className="ScrollBar"
                >
                  {topPerformer.length > 0
                    ? topPerformer.map((data, i) => {
                        return (
                          <div
                            style={{
                              color:
                                currentTheme === "dark" ? "white" : "black",
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                            className="card3-header"
                          >
                            {data.name}
                            <br />
                            <span
                              style={{
                                color:
                                  currentTheme === "dark"
                                    ? "#99999F"
                                    : "#818E94",
                                fontWeight: 400,
                                textTransform: "capitalize",
                              }}
                              className="card3-content"
                            >
                              {data.session_count} Sessions | 32 Hours
                            </span>
                            {i < topPerformer.length - 1 && (
                              <hr
                                style={{
                                  border: "none",
                                  borderBottom:
                                    currentTheme === "dark"
                                      ? "0.5px solid #A2B1C2"
                                      : "1px solid #e6e6e6",
                                }}
                              />
                            )}
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div style={{ textAlign: "start", marginLeft: "1vw" }}>
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    paddingBottom: "3px",
                    marginBottom: "5px",
                  }}
                  className="cards-title"
                >
                  Quick Stats
                </p>
                <div
                  style={{
                    width: "23vw",
                    height: "25vh",
                    backgroundColor:
                      currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "10px",
                    boxShadow: "0px 3px 6px #0000000A",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ListItemText>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        variant="body"
                        style={{
                          fontWeight: "600",
                          marginLeft: "15px",
                          color: currentTheme === "dark" ? "#FFFFFF" : "black",
                        }}
                        className="org-fonts"
                      >
                        Org.Overall Sessions
                        <span
                          style={{
                            fontWeight: "600",
                            color:
                              currentTheme === "dark" ? "#99999F" : "#818E94",
                          }}
                          className="org-content1"
                        >
                          {sortedSessions?.length > 0
                            ? sortedSessions[0]?.total_sessions
                            : ""}
                        </span>
                      </Typography>

                      <Typography
                        variant="body"
                        style={{
                          fontWeight: "600",
                          marginLeft: "5.5vw",
                          color: currentTheme === "dark" ? "#FFFFFF" : "black",
                        }}
                        className="org-fonts"
                      >
                        Org.Tested Apps
                        <span
                          style={{
                            fontWeight: "600",
                            color:
                              currentTheme === "dark" ? "#99999F" : "#818E94",
                          }}
                          className="org-content2"
                        >
                          {sortedSessions?.length > 0
                            ? sortedSessions[0]?.total_app_count
                            : ""}
                        </span>
                      </Typography>
                    </div>
                    <hr
                      style={{
                        border: "none",
                        borderBottom:
                          currentTheme === "dark"
                            ? "0.5px solid #A2B1C2"
                            : "1px solid #e6e6e6",
                        marginTop: "8vh",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body"
                        style={{
                          fontWeight: "600",
                          marginLeft: "15px",
                          marginTop: "10px",
                          color: currentTheme === "dark" ? "#FFFFFF" : "black",
                        }}
                        className="org-fonts"
                      >
                        My Allocated Hours
                        <span
                          style={{
                            fontWeight: "600",
                            color:
                              currentTheme === "dark" ? "#99999F" : "#818E94",
                          }}
                          className="org-content3"
                        >
                          32
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "20%",
                          }}
                          className="orgprog-label"
                        >
                          <div
                            style={{ marginRight: "10px", marginTop: "0.5vh" }}
                          >
                            <span style={{ marginLeft: "-0.6vw" }}>
                              Remaining
                            </span>
                            <div style={{ width: "3vw" }}>
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: "#4779EF",
                                  trailColor: "lightgrey",
                                  textColor:
                                    currentTheme === "dark" ? "white" : "black",
                                  textSize: "25px",
                                })}
                                value={remainingPercentage}
                                text={`${remainingPercentage}`}
                              />
                            </div>
                          </div>
                          <div
                            style={{ marginLeft: "10px", marginTop: "0.5vh" }}
                          >
                            <span style={{ marginLeft: "0.5vw" }}>Used</span>
                            <div style={{ width: "3vw" }}>
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: "#FEC400",
                                  trailColor: "lightgrey",
                                  textColor:
                                    currentTheme === "dark" ? "white" : "black",
                                  textSize: "25px",
                                })}
                                value={usedPercentage}
                                text={`${usedPercentage}`}
                              />
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </div>
                  </ListItemText>
                </div>
              </div>
              <div style={{ textAlign: "start", marginLeft: "1vw" }}>
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    paddingBottom: "3px",
                    marginBottom: "5px",
                  }}
                  className="cards-title"
                >
                  Session Hour Summary
                </p>
                <div
                  style={{
                    width: "15vw",
                    height: "25vh",
                    backgroundColor:
                      currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "10px",
                    boxShadow: "0px 3px 6px #0000000A",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      color: currentTheme === "dark" ? "white" : "black",
                    }}
                    className="card3-header"
                  >
                    Current Plan
                    <br />
                    <span
                      style={{
                        color: currentTheme === "dark" ? "#99999F" : "#818E94",
                      }}
                      className="card3-content"
                    >
                      Prepaid Hours
                    </span>
                    <button
                      style={{
                        height: "3vh",
                        width: "3vw",
                        backgroundColor: "#4779EF",
                        color: "#FFFFFF",
                        borderRadius: "2px",
                        marginTop: "-25px",
                        cursor: "pointer",
                        border: "none",
                        outline: "none",
                        marginLeft: "4vw",
                      }}
                    >
                      135
                    </button>
                  </div>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                    }}
                  />
                  <div
                    style={{
                      color: currentTheme === "dark" ? "white" : "black",
                    }}
                    className="card3-header"
                  >
                    Budget Spend
                    <br />
                    <span
                      style={{
                        color: currentTheme === "dark" ? "#99999F" : "#818E94",
                      }}
                      className="card3-content"
                    >
                      1,550 / 3,500
                    </span>
                  </div>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                    }}
                  />
                  <div
                    style={{
                      color: currentTheme === "dark" ? "white" : "black",
                    }}
                    className="card3-header"
                  >
                    Hours Spent
                    <br />
                    <span
                      style={{
                        color: currentTheme === "dark" ? "#99999F" : "#818E94",
                      }}
                      className="card3-content"
                    >
                      31:00 / 60:00
                    </span>
                  </div>
                </div>
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    marginLeft: "-40vw",
                    marginTop: "1vh",
                    marginBottom: "5px",
                  }}
                  className="cards-title"
                >
                  Performance Trends
                </p>
                <div
                  style={{
                    height: "43vh",
                    marginLeft: "-40vw",
                    backgroundColor:
                      currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "10px",
                    boxShadow: "0px 3px 6px #0000000A",
                    width: "55vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {/* User Dropdown */}
                    <div className="dropdown-containers">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOutsideClick(UserActive);
                          setUserActive(!UserActive);
                        }}
                        className="dropdown-buttons"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                          textTransform: "capitalize",
                        }}
                        ref={dropdownRef}
                      >
                        <div>
                          {User ? User : "User"}
                          <span className="down-arrows">&#9660;</span>
                        </div>
                      </div>

                      <div
                        className="dropdown-contents"
                        style={{
                          display: UserActive ? "block" : "none",
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                        }}
                      >
                        <div
                          className="scrollable-listss"
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                            textTransform: "capitalize",
                          }}
                        >
                          {FirstUserdata &&
                            FirstUserdata.slice()
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((data, i) => (
                                <div
                                  onClick={(e) => {
                                    setUser(e.target.textContent);
                                    auth.userId(data.id);
                                    setUserActive(!UserActive);
                                    singleUserItem(e, data, i);
                                  }}
                                  style={{
                                    backgroundColor:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "white",
                                    color:
                                      currentTheme === "dark"
                                        ? "white"
                                        : "black",
                                  }}
                                  key={i}
                                >
                                  {data.name}
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>

                    {/* Date Dropdown */}
                    <div className="dropdown-containers">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOutsideClick(DateActive);
                          setDateActive(!DateActive);
                        }}
                        className="dropdown-buttons"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                        }}
                        ref={dropdownRef}
                      >
                        {startDate ? (
                          <p style={{ fontSize: "10px" }}>
                            {formatDate(startDate)} ↔{" "}
                            {endDate ? formatDate(endDate) : ""}
                          </p>
                        ) : (
                          <p>{date}</p>
                        )}
                      </div>

                      <div
                        className="dropdown-contents"
                        style={{ display: DateActive ? "block" : "none" }}
                      >
                        <div
                          onClick={(e) => {
                            setDate(e.target.textContent);
                            setDateActive(!DateActive);
                          }}
                        >
                          <StylesProvider injectFirst>
                            <br></br>
                            <div className="dates">
                              <TextField
                                id="date"
                                label="From"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  style: {
                                    padding: 1.5,
                                    width: "120px",
                                  },
                                }}
                              />
                              <br></br>
                              <br></br>
                              <TextField
                                id="date"
                                label="To"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: startDate,
                                  style: {
                                    padding: 1.5,
                                    width: "120px",
                                  },
                                }}
                              />
                            </div>
                          </StylesProvider>
                        </div>
                      </div>
                    </div>

                    {/* Device Dropdown */}
                    <div className="dropdown-containers">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOutsideClick(DevicesActive);
                          setDevicesActive(!DevicesActive);
                        }}
                        className="dropdown-buttons"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                          textTransform: "capitalize",
                        }}
                        ref={dropdownRef}
                      >
                        <div>
                          {device ? device : "Device"}
                          <span className="down-arrows">&#9660;</span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: DevicesActive ? "block" : "none",
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                        }}
                        className="dropdown-contents"
                      >
                        <div
                          className="scrollable-lists"
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                          }}
                        >
                          {Firstdevicedata &&
                            Firstdevicedata.map((data, i) => (
                              <div
                                onClick={(e) => {
                                  setDevices(e.target.textContent);
                                  setDevicesActive(!DevicesActive);
                                  singleDeviceItem(e, data, i);
                                }}
                                style={{
                                  backgroundColor:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "white",
                                  color:
                                    currentTheme === "dark" ? "white" : "black",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.device_name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    {/* Application Dropdown */}
                    <div className="dropdown-containers">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOutsideClick(ApplicationActive);
                          setApplicationActive(!ApplicationActive);
                        }}
                        className="dropdown-buttons"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                        }}
                        ref={dropdownRef}
                      >
                        <div>
                          {application ? application : "Application"}
                          <span className="down-arrows">&#9660;</span>{" "}
                        </div>
                      </div>
                      <div
                        style={{
                          display: ApplicationActive ? "block" : "none",
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                        }}
                        className="dropdown-contents"
                      >
                        <div
                          className="scrollable-lists"
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                          }}
                        >
                          {Seconddevicedata?.map((data, i) => (
                            <div
                              onClick={(e) => {
                                setApplication(e.target.textContent);
                                setApplicationActive(!ApplicationActive);
                                singleApplicationItem(e, data, i);
                              }}
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                              }}
                            >
                              {data.app_name.replace(
                                /com|.qualcomm|.oneplus|.android|.display|.google|.tools|.internal|.emulation|.dragonfistztamilan|.network/gi,
                                function (matched) {
                                  return mapObj[matched];
                                }
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Sessions Dropdown */}
                    <div className="dropdown-containers">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOutsideClick(SessionsActive);
                          setSessionsActive(!SessionsActive);
                        }}
                        className="dropdown-buttons"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                          textTransform: "capitalize",
                        }}
                        ref={dropdownRef}
                      >
                        <div>
                          {sessions ? sessions : "Sessions"}
                          <span className="down-arrows">&#9660;</span>{" "}
                        </div>
                      </div>
                      <div
                        style={{
                          display: SessionsActive ? "block" : "none",
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                        }}
                        className="dropdown-contents"
                      >
                        <div
                          className="scrollable-lists"
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                            textTransform: "capitalize",
                          }}
                        >
                          {Firstsessiondata?.map((data, i) => (
                            <div
                              key={i}
                              onClick={(e) => {
                                setSessions(e.target.textContent);
                                setSessionsActive(!SessionsActive);
                                singleSessionItem(e, data, i);
                              }}
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                              }}
                            >
                              {data.sessionname != ""
                                ? data.sessionname
                                : "no session name"}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        zIndex: "1500",
                        pointerEvents: startDate ? "auto" : "none",
                      }}
                    >
                      <select
                        className="custom-buttons"
                        value={selectedOption}
                        onChange={handleSelectOption}
                      >
                        <option value="">Select an option</option>
                        {options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <SessionMain
                      User_id={uid}
                      currentTheme={currentTheme}
                      selectedOption={selectedOption}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginLeft: "1vw",
                  width: "40vw",
                  position: "relative",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                <div>
                  <p
                    style={{
                      color: currentTheme === "dark" ? "white" : "black",
                      marginBottom: "5px",
                    }}
                    className="cards-title"
                  >
                    Org.Recent Sessions
                  </p>
                  <div
                    value={currentTheme}
                    onChange={(e) => {
                      setCurrentTheme(e.target.value);
                    }}
                    style={{
                      display: AllSessionsActive != 404 ? "block" : "none",
                      marginLeft: "-0.5vw",
                      width: "27.5vw",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "white",
                      borderRadius: "8px",
                      height: "72vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                      scrollBehavior: "smooth",
                      boxShadow: "0px 2px 6px #0000000A",
                    }}
                    className="ScrollBar"
                    onScroll={(e) => handleScroll(e)}
                  >
                    {sortedSessions?.length > 0 ? (
                      sortedSessions.map((data, i) => (
                        <div
                          key={i}
                          onClick={(e) => {
                            setAllsessions(e.target.textContent);
                            setAllSessionsActive(!AllSessionsActive);
                            singleSessionItem(e, data, i);
                          }}
                          style={{
                            color: currentTheme === "dark" ? "white" : "black",
                            fontSize: "20px",
                            borderRadius: "5px",
                            margin: "5px",
                            width: "95%",
                          }}
                        >
                          <ListItem>
                            {data.device_name === "apple" ? (
                              <AppleIcon
                                sx={{ fontSize: 40, marginLeft: "-15px" }}
                              />
                            ) : (
                              <AndroidIcon
                                sx={{ fontSize: 40, marginLeft: "-15px" }}
                              />
                            )}
                            <ListItemText>
                              <Typography
                                variant="body"
                                style={{
                                  fontWeight: "600",
                                  marginLeft: "10px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                                title={data?.app_name}
                                className="org-appname"
                              >
                                {data?.app_name}
                              </Typography>
                              <br />
                              <Typography
                                style={{
                                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif`,
                                  marginTop: "2px",
                                  color:
                                    currentTheme === "dark"
                                      ? "#A2B1C2"
                                      : "black",
                                }}
                                id="scroll-orgfonts"
                              >
                                Created Date:{" "}
                                <span
                                  style={{
                                    color: "#4779EF",
                                    fontWeight: "600",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                  }}
                                  title={data?.session_date}
                                  id="scroll-fonts"
                                >
                                  {data?.session_date}
                                </span>
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif`,
                                  color:
                                    currentTheme === "dark"
                                      ? "#A2B1C2"
                                      : "black",
                                }}
                                id="scroll-fonts3"
                              >
                                Tested Device:{" "}
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    color: "#4779EF",
                                    fontWeight: "600",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                  }}
                                  title={data?.device_name}
                                  id="scroll-fonts"
                                >
                                  {data?.device_name}
                                </span>
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          {i < sortedSessions.length - 1 && (
                            <hr
                              style={{
                                border: "none",
                                borderBottom:
                                  currentTheme === "dark"
                                    ? "0.5px solid #A2B1C2"
                                    : "1px solid #e6e6e6",
                                margin: "5px 0",
                              }}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          fontSize: "20px",
                          fontWeight: "600",
                          color: currentTheme === "dark" ? "white" : "black",
                        }}
                      >
                        {AllSessionsActive == 404
                          ? "No Recent sessions"
                          : "No Recent logs!"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
