import React from "react";
import Sidebar from "./Sidebar/Sidebar";
import Header from "../Header";

function Layout() {
  return (
    <div>
      <Header />
      <Sidebar />
    </div>
  );
}

export default Layout;
